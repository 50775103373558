import Vue from 'vue';
import App from './App.vue';
import router from '@/router/index';
import './assets/css/style.min.css';
import store from "@/store";
import './plugins';

import './_helpers';
import './assets/css/main.scss'
import infiniteScroll from "vue-infinite-scroll";
import Common from "@/components/Common";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {VueMaskDirective} from "v-mask";
import VueMask from 'v-mask';
import VModal from 'vue-js-modal'
import {changeLang} from "@/_helpers";
// import { BootstrapVue, DropdownPlugin } from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
//
// Vue.use(BootstrapVue)
// Vue.use(DropdownPlugin)
// import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(VueMask);
// Vue.component('multiselect', Multiselect);
Vue.component("v-select", vSelect);
Vue.directive('mask', VueMaskDirective);
const common = Vue.prototype.$common = new Vue(Common).$mount();
document.body.appendChild(common.$el);

Vue.use(infiniteScroll);
Vue.config.productionTip = false;
Vue.use(VModal);

Vue.use(changeLang)

router.beforeEach((to, from, next) => {

        window.scrollTo(0, 0);
    // if (to.name=='programs'&&to.params&&to.params.locale=='en')
    //     next({name:'main',params:{locale:'en'}})
    // if (!store.state.auth.authorized && to.name != 'login'&&to.name!='teh') {
    //
    //     next({name: 'teh'});
    // }
    // else

        next();
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
