import requestApi from "@/_api/request.api";
import {handleError, setTokenToClient} from "@/_api";
import {AUTH, RESET} from "@/_types/store-types";
import {LS_TOKEN, LS_USERS} from "@/_types";
import store from "@/store";

const requestService = {
    newsList(data) {
        return requestApi.newsList(data).then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    }, mainNews() {
        return requestApi.mainNews().then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    }, getNewsItem(id) {
        return requestApi.getNewsItem(id).then(res => {
            return res;
        })
            .catch(handleError);
    },
    getStats(data) {
        return requestApi.getStats(data).then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    },
    getFactItem(id) {
        return requestApi.getFactItem(id).then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    },
    getData() {
        return requestApi.getData().then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    },
    getPrograms() {
        return requestApi.getPrograms().then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    },
    getFacemarket(form) {
        return requestApi.getFacemarket(form).then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    },
    getVideos(data) {
        return requestApi.getVideos(data).then(res => {
            return res;
        })
            .catch(err => {
                return err;
            });
    },
    sendVideo(data) {
        return requestApi.sendVideo(data).then(res => {
            return res;
        })
            .catch(handleError);
    },
    sendProblem(form) {
        return requestApi.sendProblem(form).then(res => {
            return res;
        }).catch(handleError);
    },
    getSlider(data) {
        return requestApi.getSlider(data).then(res => {
            return res
        }).catch(err => {
            return err;
        })
    },
    getStaff() {
        return requestApi.getStaff().then(res => {
            return res
        }).catch(err => {
            return err;
        })
    },
    async login(email, password) {
        return await requestApi.login(email, password)
            .catch(handleErrorL)
            .then(handleSuccess)
            .then(({token}) => {
                this.setAuthorization(email, password, token);
            });
    },
    setAuthorization(phone, password, token) {
        const authdata = window.btoa(phone + ':' + encodeURIComponent(password));
        localStorage.setItem(LS_USERS, JSON.stringify(authdata));
        localStorage.setItem(LS_TOKEN, token);
        setTokenToClient(token);
        store.commit(AUTH + RESET);
    },
    support(data) {
        return requestApi.support(data).then(res => {
            return res
        }).catch(handleError)
    },
    getNakazData() {
        return requestApi.getNakazData().then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    },
    getStatTags() {
        return requestApi.getStatTags().then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    },
    getRoshTags() {
        return requestApi.getRoshTags().then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    }

};

function handleErrorL({message, response}) {
    const {status, data} = response;
    const errors = [];
    if (status === 400) {
        errors.push({status, message: 'wrong username or password'});
    } else if (status === 422) {
        Object.keys(data.data.errors).forEach(field => {
            data.data.errors[field].map((err, index) => {
                errors.push({status: field + '' + index, message: err});
            });
        });
    } else {
        errors.push({status, message});
    }
    throw errors;
}

function handleSuccess(res) {
    if (res.data) return res.data;
    throw {data: ''};
}

export default requestService;