<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="row">
          <div class="col-lg-2 col-end-12">
            <div class="logo" @click="pushTo('main')" style="cursor: pointer">
              <img src="@/assets/img/logo.png" alt=""/>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-4 col-12">
            <div class="footer__links">
              <div
                  v-for="item in routes.filter((x) => x.condition == true)"
                  :key="item.route"
              >
                <a
                    href="#"
                    class="footer__link"
                    @click.prevent="pushTo(item.route)"
                >{{ item.name }}</a
                >
              </div>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-4 col-12">
            <div class="footer__wrap">
              <div class="social">
                <span>{{ $t("social_networks") }}</span>
                <div class="social__wrap">
                  <img
                      src="@/assets/img/facebook.svg"
                      alt=""
                      @click="toFacebook()"
                  />
                  <img
                      src="@/assets/img/social-2.svg"
                      alt=""
                      @click="toInst()"
                  />
                  <img
                      src="@/assets/img/telegram-desktop.svg"
                      alt=""
                      @click="toTelegram()"
                  />
                  <img
                      src="@/assets/img/social-4.svg"
                      alt=""
                      @click="toYoutube()"
                  />
                </div>
              </div>
              <language/>
            </div>
            <br/>
            <br/>
            <div style="display: flex; flex-direction: column">
              <button @click="show()" class="button footer__support-btn">
                {{ $t('support') }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="footer__qjt">{{ $t("sign") }}</div>
          </div>
          <div class="col-lg-4">
            <div class="footer__text1">
              {{ $t("footer_text_1") }}
            </div>
          </div>
          <div class="offset-lg-1 col-lg-4">
            <div class="footer__text2">
              {{ $t("footer_text_2_1") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
        name="support-modal"
        :maxWidth="526"
        :width="'95%'"
        :adaptive="true"
        :height="'max-content'"
    >
      <div class="support-modal__close">
        <img @click="hide()" src="../assets/img/x-circle.svg" alt=""/>
      </div>
      <div class="support-modal__content" v-if="active_tab==1">
        <div class="title">{{ $t('support_title') }}</div>
        <div class="subtitle">
          {{ $t('support_text') }}
        </div>
        <div class="text-field">
          <label for="" class="label">{{ $t('fio_placeholder_s') }}</label>
          <input type="text" class="input" :placeholder="$t('fio')" v-model="form.fio"
                 :class="{ field_error: errors.fio }"/>
        </div>
        <div class="text-field">
          <label for="" class="label">{{ $t('email_placeholder_s') }}</label>
          <input type="text" class="input" placeholder="mail@mail.ru" v-model="form.email"
                 :class="{ field_error: errors.email }"/>
        </div>
        <div class="text-field textarea-field">
          <label for="" class="label">{{ $t('text_placeholder_s') }}</label>
          <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              class="textarea"
              :class="{ field_error: errors.description }"
              :placeholder="$t('write_placeholder')"
              v-model="form.description"
              @input="handleInput"
          ></textarea>
        </div>
        <button class="button" @click="sendSupport" :disabled="loading">{{ $t('send_support') }}</button>
      </div>
      <div v-if="active_tab==2">
        <section class="nakaz-success" >
      <div class="container">
        <div class="nakaz-success__inner">
          <p>{{ $t("thx") }}</p>
          <p v-html="$t('thx_descr')"></p>
        </div>
        <div style="text-align: center">
          <button
              class="nakaz-success__button"
              @click="hide"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </section>
      </div>
    </modal>
    <modal-window
        ref="close_modal"
        id="modal_itm"

    >
      <div style="height: 100%;width: 100%;background-color: white!important;border-radius: 16px!important;text-align: center" >
      <template>
        <p style="padding: 70px 50px!important;"><span style="font-weight: 600;font-size: 22px">{{ $t('close_text') }}</span></p>
      </template>
        </div>
    </modal-window>
  </footer>
</template>

<script>
import Language from "@/components/Language";
import requestService from "@/_services/request.service";
import ModalWindow from "@/components/Modal";

export default {
  name: "Footer",
  components: {Language,ModalWindow},
  data() {
    return {
      form: {
        fio: '',
        email: '',
        description: ''
      },
      loading: false,
      errors: [],
      active_tab: 1
    }
  },
  methods: {
    handleInput(event) {
      if (this.form.description.length < 1500) {
        this.form.description = event.target.value;
      } else this.form.description = event.target.value.slice(0, 1500);
    },
    sendSupport() {
      this.errors = []
      this.loading = true
      requestService.support(this.form).then(res => {
        this.loading = false
        this.form = {
          fio: '',
          email: '',
          description: ''
        };
        this.active_tab = 2
      }).catch(err => {
        this.errors = err.response.data.errors
        this.loading = false
      })
    },
    toFacebook() {
      window.open("https://www.facebook.com/Toqaev2022", "_blank");
    },
    toInst() {
      window.open("https://www.instagram.com/toqaev2022/", "_blank");
    },
    toYoutube() {
      window.open(
          "https://youtube.com/channel/UCFSxyV97uoGMR4ztETM66vw",
          "_blank"
      );
    },
    toTelegram() {
      window.open("https://t.me/toqaev2022", "_blank");
    },
    pushTo(name) {
      // if (['nakaz','nakaz2'].includes(name))
      // {
      //   this.$refs.close_modal.showModal()
      // }
      // else {
        if (this.$route.name == name) window.scrollTo(0, 0);
        else return this.$router.push({name: name});
      // }
    },
    show() {
      this.active_tab = 1
      this.$modal.show("support-modal", {
        width: 300,
        height: 300,
      });
    },
    hide() {
      this.$modal.hide("support-modal");
    },
  },
  computed: {
    routes() {
      return [
        {
          name: this.$t("biography"),
          route: "bio",
          condition: true,
        },
        {
          name: this.$t("place"),
          route: "headquarters",
          condition: true,
        },
        {
          name: this.$t("news"),
          route: "news",
          condition: true,
        },
        {
          name: this.$t("program"),
          route: "programs",
          condition: true,
        },
        {
          name: this.$t("video_letter"),
          route: "video",
          condition: true,
        },
        {
          name: this.$t("support_letter"),
          route: "nakaz2",
          condition: true,
        },
        {
          name: this.$t("rosh"),
          route: "rosh",
          condition: true,
        },
        {
          name: this.$t("nakaz"),
          route: "nakaz",
          condition: true,
        },
        {
          name: this.$t("work_results"),
          route: "facts",
          condition: true,
        },
        {
          name: this.$t("media"),
          route: "",
          condition: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.field_error {
  border: 1px solid #e22626 !important;
  border-radius: 5px;
}

.footer__links div a {
  text-transform: none !important;
}

.footer__support-btn {
  color: #00424c !important;
  background: #a1e2eb !important;
  border-radius: 5px !important;
  max-width: 186px !important;
  height: 39px !important;
}

.support-modal {
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__content {
    .title {
      font-size: 1.625rem;
      font-weight: 500;
      text-align: center;
    }

    .subtitle {
      margin-top: 20px;
      text-align: center;
      color: #717171;
      font-size: 18px;
      line-height: 21px;
    }

    .text-field {
      margin-top: 30px;

      input {
        width: 100%;
        border: 1px solid #dadada;
        border-radius: 5px;
      }
    }

    .button {
      margin-top: 30px;
    }
  }
}

.footer .vm--modal {
  background: #f9f9f9 !important;
  border-radius: 10px !important;
  padding: 80px;
  position: relative;
  max-height: 95vh !important;
  overflow: auto;
  margin: 2.5vh 0;
}

@media (max-width: 767px) {
  .footer .vm--modal {
    background: #f9f9f9 !important;
    border-radius: 10px !important;
    padding: 60px 20px 60px 20px;
    position: relative;
    max-height: 95vh !important;
    overflow: auto;
    margin: 2.5vh 0;
  }

  .support-modal {
    &__content {
      .title {
        font-size: 20px;
      }

      .subtitle {
        font-size: 16px;
        line-height: 19px;
      }

      .button {
        margin-top: 25px;
      }

      .text-field {
        label {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
