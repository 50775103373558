import Vue from "vue";
import Router from 'vue-router';
import Localizer from "@/providers/LocalizationProvider";
import {checkLocale, requireAuth} from "@/router/router-guards";
import routes from "@/router/routes";
import i18nService from "@/_services/i18n.service";

Vue.use(Router);
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: `/${i18nService.getCurrentLocale()}`,
        },
        {
            path: '/:locale',
            component: Localizer,
            beforeEnter: checkLocale,
            children: [...routes]
        },
        {
            path: '/*',
            component: () => import('../views/404'),
        }
    ]
});


const DEFAULT_TITLE = 'QJT';
router.afterEach((to, from, next) => {
    Vue.nextTick(() => {
        document.title = Vue.prototype.$t(to.meta.title) + " - Toqaev2022" || DEFAULT_TITLE;
        if (to.meta.description)
            document.head.querySelector('meta[name="description"]').content = Vue.prototype.$t(to.meta.description)
    });

    next;
});

export default router;