export default {
    'ru': 'рус',
    'kk': 'қаз',
    'en': 'eng',
    'biography': 'Өмірбаяны',
    'program': 'Бағдарлама',
    'news': 'Жаңалықтар',
    'contacts': 'Байланыс',
    'main_title': '“Мен азаматтарымыздың ертең емес, бүгін бақытты өмір сүргенін қалаймын”',
    'fio_president': 'Қасым-Жомарт Кемелұлы Тоқаев',
    'section2_title': 'Біз әділ Қазақстанды – барлығының тең мүмкіндіктері бар елді құрудамыз.',
    'section2_description': 'Әрбір азамат тұрғылықты жеріне қарамастан, лайықты өмір сүруге және өзін-өзі толық іске асыруға құқылы, өйткені ұлттық байлық еліміздің барлық тұрғындарының еңбегі мен күш-жігерінің арқасында жасалады.',
    'section3_title': 'Елбасы да, үкімет те қолдап, әрбір сынға жедел жауап берді.',
    'section3_link': 'Барлық орындалған жұмыстарды қараңыз',
    'stats_1': '<div>25%</div>\n' +
        '<div>Мұғалімдердің жалақысы 25% өсті</div>\n' +
        '<div>Республика бойынша мұғалімдердің орташа жалақысы 343 мың теңгені құрайды. 2023 жылдың қаңтарынан бастап қосымша 25% артуы күтілуде.</div>',
    'stats_2': '<div>11 млрд.тг</div>\n' +
        '<div>11 миллиардтан астамы қайырымдылыққа жіберілді</div>' +
        '<div>«Қазақстан халқына» қоры қаңтар оқиғасында зардап шеккендерге, ерекше күтімді қажет ететін балаларға, шетелде емделуге мұқтаж балаларға қайырымдылық көмек көрсетті.</div>',
    'stats_3': '<div>1,7 млн.</div>\n' +
        '<div>1,7 миллион қазақстандық зейнетақы жинақтарын пайдаланды</div>\n' +
        '<div>Алғаш рет зейнетақы жинақтарын тұрғын үй жағдайын жақсарту және емдеу үшін пайдалану мүмкін болды</div>',
    'stats_4': '<div>3 жыл</div>' +
        '<div>ШОБ субъектілері 3 жылға салықтан босатылады</div>' +
        '<div>Шағын және орта бизнесті тексеруге мораторий енгізілді.</div>',
    'stats_5': '<div>2,9 млн/га</div>' +
        '<div>Жер мен оның қойнауы енді халықтықі!</div>' +
        '<div>2,9 миллион гектар жер мемлекет қорына қайтарылды. Жерді шетелдіктерге сатуға тыйым салынды.</div>',
    'stats_6': '<div>700 әкім</div>' +
        '<div>Әкімдерді сайлау</div>' +
        '<div>2021 жылдың 25 шілдесінде алғаш рет 700-ден астам ауыл әкімін сайлау өтті.</div>',
    'section4_title': 'Барлық жағынан әділдік – мемлекеттігіміздің берік іргетасы!',
    'section4_description': '<p>Болашақ Президент әрбір қазақстандықтың лайықты жұмыс пен әділ еңбекақы алуы үшін қажеттінің бәрін жасауға міндетті.</p>' +
        '<p>Әрбір азаматтың сапалы мемлекеттік қызметтерді пайдалануын қамтамасыз ету. Қолжетімді заманауи медицина. Жетілдірілген білім. Заңмен толық қорғалу. Болашаққа сеніммен қарау.</p>',
    'medicine': 'Медицина',
    'medicine_descr': 'Денсаулық сақтау саласы көп қаржыны қажет етеді. Бұл инфрақұрылым, кадр, дәрі-дәрмекпен қамтамасыз ету туралы.',
    'education': 'Білім',
    'education_descr': 'Қазақстандық білім мен ғылымның алдында жаңа тенденцияларға ілесіп қана қоймай, бір қадам алға жүру, трендтерді генерациялау сияқты ауқымды, өзекті міндет тұр.',
    'social': 'Әлеуметтік қорғау',
    'social_descr': 'Қазақстан – әлеуметтік мемлекет. Қиын жағдайда қалған азаматтарға жан-жақты көмек көрсету – біздің басым ұстанымымыздың бірі.',
    'security': 'Қауіпсіздік',
    'security_descr': 'Көршілес елдер арасында үлкен соғыс жүріп жатыр. Осыны ескере отырып, біз ең алдымен елдің қауіпсіздігін ойлауымыз керек.',
    'regional': 'Аймақтық даму',
    'regional_descr': '«Естішу мемлекеттің» басты қағидасы – мемлекеттік аппарат азаматтардың мүддесі үшін жұмыс істеуі керек. Бұл бірінші кезекте жергілікті билікке қатысты.',
    'economy': 'Экономика',
    'economy_descr': 'Біздің стратегиялық мақсатымыз – Орталық Азиядағы жетекші рөлімізді және жаһандық экономикадағы позициямызды нығайту.',
    'all_program': 'Толық бағдарламаны қарау',
    'all_program_descr': 'Тиімді мемлекеттік басқару Қолжетімді баспана, таза экология ...',
    'new_initiative_descr': 'Қазақстанды дамыту бойынша өз бастамаңызды жазыңыз',
    'new_initiative': 'Бастама жазу',
    'section5_title': 'Біздің алдымызда ауқымды ұлттық міндеттер тұр.',
    'section5_descr': 'Сайлауалды бағдарламамды ашық деп жариялаймын. Еліміздің түкпір-түкпірінен тапсырыстар жинап, жұмыс бағдарламасына конструктивті ұсыныстар енгізуге дайынмын.',
    'section5_button': 'Ұсынысыңызды жіберу',
    'all_news': 'Барлық жаңалықты көру',
    'place': 'Аймақтық штабтар',
    'social_networks': 'Әлеуметтік желілер:',
    'footer_text_1': 'Қазақстан Республикасының президенттігіне кандидат Қ.Тоқаевтың ресми сайты. М. Ж. Оспановтың тапсырысы бойынша Қазақстан Республикасының президенттігіне кандидат Қ.Тоқаевтың сайлау қорының қаражаты есебінен дайындалды.',
    'footer_text_2': 'Осы сайттағы барлық үгіт материалдары кандидаттың уәкілетті сенім білдірілген адамдарының тапсырысы бойынша дайындалады және кандидаттың сайлау қорының қаражатынан төленеді.',
    'sign': '© 2022 Қасым-Жомарт Тоқаев',
    'slider2_1': 'Біз жаңа сын-қатер тегеурініне төтеп бердік. Төзімділік пен ынтымақтастық көрсеттік. Ең қиын жағдайларда күнделікті мәселелерді шешіп қана қоймай, елдің прогрессивті дамуын қамтамасыз еттік',
    'slider2_1_sign': 'Қасым-Жомарт Кемелұлы Тоқаев',
    'last': 'Біз Тәуелсіздік жылдарында түрлі қиындықты еңсеріп, айтулы жетістіктерге жеттік. Бірлігіміздің арқасында қасиетті Тәуелсіздігімізді сақтап қалдық. Сын-қатердің бәрін өткеріп, осы күнге жеттік. Уақыт сынағы қандай ауыр болса да, бет алған бағдарымыздан айнымаймыз. Осы жолда жұртымызға, ең алдымен, ауызбіршілік керек. Ақыл мен сабыр, парасат пен ұстамдылық қажет. Мен ең қиын сәтте, қандай жағдай болса да, ақырына дейін халқыммен бірге боламын.',
    'total_news_pl': 'Барлығы {n} жаңалық :::Барлығы {n} жаңалық :::Барлығы {n} жаңалық',
    'main_page': 'Басты бет',
    'all_news_page': 'Барлық жаңалық',
    'recommended_news': 'Таңдаулы жаңалықтар',
    'all_news_more': 'Толығырақ жаңалықтар',
    'share': 'Бөлісу',
    'all_facts': 'Барлығы',
    'facts_title': 'Жемісті істер жылнамасы',
    'facts_description': 'Біз азаматтардың сенімін қалпына келтіріп, цифралар, фактілер, іс-әрекетпен сендіре отырып, олардың сыны мен сындарлы ұсыныстарына жедел ден қоюымыз керек.',
    'recommended_facts': 'Таңдаулы оқиғалар',
    'check_all': 'Барлығын қараңыз',
    'nakaz_title': 'Біз шешім қабылдау үдерісіне қоғам  араласатын, сол арқылы елді қуатты ететін ашық мемлекет құрамыз',
    'nakaz_description': 'Ұсыныс жазыңыз, біз сындарлы диалогке және өтініштерге уақтылы жауап беруге дайынбыз.',
    'gender_m': 'Ер',
    'gender_f': 'Әйел',
    'gender': 'Жыныс',
    'fio_placeholder': 'Аты-жөні',
    'send': 'Жіберу',
    'select_living_place': 'Тұрғылықты мекенжайы',
    'select_living_type': 'Елді мекен түрі',
    'other': 'Басқа',
    'select_placeholder': 'Таңдау',
    'string_length': 'Символ саны: {n}',
    'working_type': 'Ұсынылған тізімнен қызмет аясын таңдаңыз, егер сәйкес болмаса, «Басқа» нұсқасын таңдаңыз:',
    'nakaz_theme': 'Сайлаушылардың талап-тілегіне сай тақырып таңдаңыз немесе ұсынылған тізімнен алыңыз. Егер тізімнен тақырып таба алмасаңыз, «Басқа» нұсқасын таңдаңыз:',
    'nakaz_placeholder': 'Арнайы терезеге тапсырыс немесе ұсыныс мәтінін енгізіп, "жіберу" түймесін басыңыз.',
    'thx': 'Рахмет!',
    'thx_descr': 'Өз ұсынысыңызды жібергеніңіз үшін алғысымызды білдіреміз. <br/> ',
    'total_problems': '{n} адам:::{n} адамның',
    'program_new': 'Тек қана сіздің қолдауыңызбен Әділетті Қазақстанның іргетасын бірге қалай аламыз.',
    'all_program_descr_1': 'Тиімді мемлекеттік басқару',
    'all_program_descr_2': 'Қолжетімді баспана, таза экология...',
    'new_initiative_descr_1': 'Менің Қазақстанды дамытуға арналған бастамам бар!',
    'footer_text_2_1': 'Сайтта жарық көрген үгіт-насихат материалын сайлауға түсіп жатқан үміткердің құзіретті тұлғалары қаражат төлеп орналастырды, қаражат үміткердің сайлауалды қорынан төленді.',
    'program_theme': 'Үміткердің үндеуі',
    'program_title': 'Қазақстан Республикасы президенттігіне үміткер   Қасым-Жомарт Кемелұлы Тоқаевтың сайлауалды бағдарламасы ',
    'program_title_small': 'Құрметті, Отандастар!',
    'program_text': '<p> Біз – біртұтас Қазақстан халқымыз. Бізді қасиетті жерге деген ортақ құндылықтарымыз біріктіреді: бейбітшілік, әл-ауқат, өркендеу, әділеттілік, барлығына тең мүмкіндіктер. Біздің елдің тарихы ұлы. Тәуелсіз, қуатты елде тұрып жатырмыз. Бағалайтын, сақтайтын дүниелеріміз көп, сол себепті болашаққа деген сенім мығым, мақсат бекем. Бірлігіміз баянды. </p>',
    'staff': 'Аймақтық штабтар',
    'video_letter': 'Бейнеүндеу',
    'completed_tasks': 'Орындалған тапсырмалар',
    'rosh': 'Республикалық Қоғамдық Штаб',
    'support_letter': 'Тілекхат',
    'back': 'Артқа',
    'main': 'Басты бет',
    'completed_work': 'Атқарылған жұмыстар',
    'comp_1': 'Қазақстан Республикасының Президенттігіне үміткер Қасым-Жомарт Тоқаевтың үгіт-насихат науқанын ұйымдастыру үшін республикалық қоғамдық штаб құрылды',
    'comp_2': 'Қ. К. Тоқаев мырзаның саяси тұлғасы төңірегіне  Әділетті Қазақстанды бірге құруға дайын ел патриоттары топтасты.',
    'comp_3': 'Олардың әрқайсысы үгіт-насихат науқанына белсенді қатысып, Қасым-Жомарт Тоқаевтың сайлауалды бағдарламасын түсіндіреді.',
    'comp_4': 'Егер сіз біздің қоғамдық қозғалысты қолдағыңыз келсе, өзіңіздің видео үндеуіңізді біздің парақшамызға жүктеңіз',
    'send_video': 'Егер сіз біздің қоғамдық қозғалысты қолдағыңыз келсе, өзіңіздің видео үндеуіңізді біздің парақшамызға жүктеңіз',
    'all_init': 'Бастамалар',
    'look': 'Қараңыз',
    'fact_1': 'Мемлекет пен халық мүддесі бір!',
    'fact_2': 'Біз демократия, заң үстемдігі мен теңдік қағидаттарын сақтайтын және құрметтейтін қуатты мемлекет құрамыз. Елімізде ойы еркін, рухы азат ұрпақ өсіп келеді. Осы өскелең ұрпаққа жол ашу үшін түбегейлі өзгерістер жасаймыз.',
    'fact_3': 'Біздің Отанымыз – бір, мемлекетіміз – бір, халқымыз – бір, сондықтан әрқашан да бірге болайық!',
    'fact_4': 'Жемісті істер жылнамасы',
    'results': 'Нәтиже',
    'fact_5': 'Біздің реформалардың басты мақсаты – Әділетті Қазақстан құру',
    'staff_region': 'Штаб аймағын таңдаңыз',
    'leader': 'Жетекші',
    'send_2022': 'Жолдау 2022-2029',
    'load_more': 'Толық жүктеу',
    'problem_form_1': 'Ашық мемлекетте қоғам қабылданған шешімдерге араласады және елді мықты етеді ',
    'problem_form_2': 'Бізге жазыңыз, біз сындарлы диалог жүргізуге және азаматтардың өтініштеріне уақтылы жауап беруге дайынбыз.',
    'fio': 'Аты-жөні',
    'phone_num': 'Телефон номері',
    'leave_place': 'Тұрғылықты мекенжайды таңдаңыз',
    'region_type': 'Елді мекеннің түрі',
    'to_main': 'Басты бетке оралыңыз',
    'nakaz': 'Ұсыныс',
    'form2_1': 'Хатыңызды жолдаңыз.',
    'form2_2': '',
    'form2_3': 'Тұрғылықты мекенжайы',
    'region': 'Аймақ',
    'district': 'Аудан',
    'form2_4': 'Хат мәтінін теріңіз.',
    'form3_1': 'Президенттікке үміткер Қ.Тоқаевқа арналған тілек-талаптарыңызды жолдаңыз.',
    'already_send': 'Жібергендер ___ адам',
    'write_letter': 'Хат жазыңыз',
    'write': 'Жазу',
    'program_themes': 'Бағдарлама бөлімі',
    'program_1': 'Сайлауалды тұғырнама <p>“Әділетті Қазақстан: бәріміз және әрқайсымыз үшін”.\nҚазір және әрдайым</p>',
    'program_2': 'Бағдарламаны көру',
    'program_3': '"Әділетті Қазақстан" үшін өзекті, пайдалы ой-пікір, ұсынысыңызды жолдаңыз',
    'program_4': 'Ұсынысты жазыңыз',
    'vide_letter': 'Видео үндеу',
    'video1_1': 'Видеоңызды жариялаңыз',
    'video2_2': 'Видеоңыздың youtube сілтемесін қойыңыз',
    'video_3': 'Сілтеме дұрыс емес',
    'video_4': '<span>1. Видеоңыздың youtube сілтемесін қойыңыз.; </span>\n' +
        '                <span>2. Видео қолжетімді болу үшін парақшаңызды ашыңыз;</span>\n' +
        '                <span>3. Видео ұзақтығы 1 минуттан аспауы тиіс;</span>\n' +
        '                <span>\n' +
        '                  4.  Дінаралық және ұлтаралық араздық тудыратын пікірге тыйым салынады\n' +
        '                </span>',
    'all_video': 'Қолдау бейнеүндеулер',
    'video3_1': 'Президенттікке үміткер Қ.Тоқаевты қолдау бейнеүндеуіңізді жүктеңіз',
    'video3_2': 'Барлық бейнеүндеуді қараңыз',
    'video3_3': 'Видеоны жіберіңіз',
    'bio_1': '',
    'bio_2': '17 мамыр 1953 жыл',
    'bio_3': 'Қасым-Жомарт Тоқаев 1953 жылы 17 мамырда Алматы қаласында, зиялы қауым отбасында дүниеге келді.',
    'bio_4': ' Әкесі – Кемел Тоқайұлы Тоқаев (1923-1986) Ұлы Отан соғысының ардагері, белгілі жазушы, қазақ әдебиетінің детективті-шытырман оқиғалы жанрының негізін қалаушы. Шешесі – Тұрар Шабарбаева (1931-2000) Алматы шет тілдері педагогикалық институтында қызмет еткен.',
    'bio_5': 'Мектеп және университет',
    'bio_6': '1970–1975 жылдар',
    'bio_7': 'Қасым-Жомарт Тоқаев Алматыдағы француз тілін тереңдетіп оқытатын № 25 орта мектепті үздік бітірген.',
    'bio_8': 'Ол Мәскеу мемлекеттік халықаралық қатынастар институтының түлегі (1975 ж, ММХҚИ СІМ КСРО). Қазақстаннан келген жас жігітке дипломаттар мен мемлекет қайраткері балаларының арасында, бәсекелес ортада оқу оңай болмады. Қасым-Жомарт Кемелұлы институтта үздіктер қатарында жүрді.',
    'bio_9': '',
    'bio_10': '',
    'bio_11': 'ММХҚИ-ді бітірген соң, Тоқаев дипломат мансабын КСРО Сыртқы істер министрлігінде кезекші референт қызметінен бастады. Қызметтегі жетістіктері үшін Сингапурдағы кеңес елшілігіне жіберілді.',
    'bio_12': '1979 жылы төрт жылдық шетелдік ұзақ іссапардан соң КСРО Сыртқы істер министрлігінің аппаратына оралды.',
    'bio_13': '1983 жылы Қасым-Жомарт Тоқаев Қытайда Бейжің лингвистикалық институтында он айлық тағылымдамадан өтті. Екі жылдан кейін 1985 жылы Пекиндегі кеңес елшілігіне дипломат болып тағайындалып, онда 1991 жылға дейін екінші хатшы, бірінші хатшы және кеңесші қызметтерін абыроймен атқарды.',
    'bio_14': 'Дипломатиялық қызметте жүріп, ол үстел теннисі турнирінде өнер көрсетті. Жас Қасым-Жомарт Тоқаевтың 1986 жылы Бейжіңде түсірілген суреті. (сурет)',
    'bio_15': '',
    'bio_16': '',
    'bio_17': '1992 жылы мамырда Қазақстан ЮНЕСКО-ға мүше болды. Еліміздің ЮНЕСКО тізіміне енген алғашқы нысаны Қожа Ахмет Яссауи кесенесі еді. ',
    'bio_18': 'Суретте Қасым-Жомарт Тоқаев ЮНЕСКО-ның Бас директоры Федерико Майормен, 1992 жылғы мамыр, Париж. (сурет)',
    'bio_19': 'Қазақстан тәуелсіздік алғаннан кейін Қасым-Жомарт Тоқаев Қазақстан Республикасының тұңғыш Сыртқы істер министрі Төлеутай Сүлейменовтың орынбасары болды.',
    'bio_20': 'Қасым-Жомарт Тоқаев Қазақстан Республикасының Сыртқы істер министрі ретінде 10 жылға жуық қызмет істеді. 1994 жылдан 1999 жылға дейін және 2003 жылдан 2007 жылдар аралығында.',
    'bio_21': 'Сыртқы істер министрі ретінде ядролық қаруды таратпауға елеулі үлес қосады. 1996 жылы Нью-Йорк қаласында БҰҰ Бас Ассамблеясының 50-ші сессиясында Қасым-Жомарт Тоқаев Ядролық сынақтарға жан-жақты тыйым салу туралы шартқа қол қойды. 2005 жылы ол Семей қаласында Орталық Азияда ядролық қарудан азат аймақ (ЦАЗЯСО)  құру жөніндегі Келісімге қол қойды.',
    'bio_22': 'Мақат Садықтың жеке мұрағатынан. 1998 жыл. Шетелге сапар. (сурет)',
    'bio_23': '1999 жылдан 2002 жылға дейін Қасым-Жомарт Тоқаев Премьер-Министрдің орынбасары, кейін Қазақстан Республикасының Премьер-Министрі болып қызмет етті.',
    'bio_24': '2000 жыл. Қасым-Жомарт Тоқаевтың Үкімет басшысы ретінде елдегі аймақтардың біріне жасаған сапары. (сурет)',
    'bio_25': 'Оның премьерлік қызметі Тәуелсіз Қазақстан тарихындағы қиын кезеңмен тұспа-тұс келді. Қасым-Жомарт Тоқаевтың басшылығымен ел үкіметі орнықты экономикалық өсуге бағдар ала алды. Нәтижесінде инвестициялық ахуал жақсарды, халықты жұмыспен қамту деңгейі артты. Мұның бәрі халықтың әл-ауқаты мәселесінің Қасым-Жомарт Тоқаев үшін қаншалықты басым болғанын айқын көрсетеді.',
    'bio_26': 'ҚАЗАҚСТАН РЕСПУБЛИКАСЫНЫҢ МЕМЛЕКЕТТІК ХАТШЫСЫ-СЫРТҚЫ ІСТЕР МИНИСТРІ 2002-2003 ЖЫЛДАР',
    'bio_27': 'Жұмыс кезінде ол АҚШ президенті Джордж Бушпен кездесті. (сурет)',
    'bio_28': '2007-2011 ЖӘНЕ 2013-2019 ЖЫЛДАРДА ҚАЗАҚСТАН РЕСПУБЛИКАСЫ ПАРЛАМЕНТІ СЕНАТЫНЫҢ ТӨРАҒАСЫ',
    'bio_29': 'Қасым-Жомарт Тоқаев ҚР Парламенті Сенатын 10 жылдан астам басқарды. Осы жылдар ішінде ол мемлекеттің құқықтық саясаты туралы нақты түсінік қалыптастырды. Қазақстанның әлеуметтік-экономикалық дамуының барлық құқықтық негіздері мен түрлі реформалары Қасым-Жомарт Кемелұлы арқылы өтті.',
    'bio_30': 'Демек, Қасым-Жомарт Тоқаев жиі айтатын құқықтық және ашық мемлекет қағидаттары туралы терең білім осы жылдары жинақталды. Дәл осы кезеңде Парламент мемлекеттің демократиялық құрылымын күшейтетін заңнамалық түзетулердің едәуір пакетін әзірлеп, қабылдады.',
    'bio_31': 'Қасым-Жомарт Тоқаев БҰҰ Бас хатшысының орынбасары, БҰҰ-ның Женева қаласындағы Еуропалық бөлім басшысы қызметтерін атқарды. Қарусыздандыру жөніндегі конференцияда БҰҰ Бас хатшысы Пан Ги Мунның жеке өкілі болды.',
    'bio_32': 'Қасым-Жомарт Тоқаев және Пан Ги Мун. (сурет)',
    'bio_33': 'Ол қарусыздандыру бойынша Конференцияның Бас хатшысы қызметін атқарды. (сурет)',
    'bio_34': '13 қыркүйек 2012 жыл. АҚШ-тың Мемлекеттік хатшысы Джон Керри мен Ресей Сыртқы істер министрі Сергей Лавров БҰҰ-ның Женевадағы бөлімшесінің бас директоры Қасым-Жомарт Тоқаевтың сүйемелдеуімен Сириядағы азаматтық соғыс бойынша саяси шешім қабылдау жөніндегі кездесуде. (сурет)',
    'bio_35': '',
    'bio_36': 'Қасым-Жомарт Тоқаев кезектен тыс президенттік сайлауда 70,96% дауыс жинап, жеңіске жетті және Қазақстан Республикасының Президенті болып сайланды. ',
    'bio_37': 'Президенттің алғашқы Жарлықтарының бірі халықты әлеуметтік қорғауға, өмірлік қиын жағдайға тап болған адамдарға қарыз ауыртпалығын жеңілдетуге  қатысты болды.Ол Үкімет пен Ұлттық Банкке мүмкіндігі шектеулі балалары бар немесе асыраушысынан айырылған және көпбалалы отбасы қарыздарын өтеуді тапсырды. Бұл бастама 3 миллион азаматты, яғни ел халқының 16%-ын қамтыды.',
    'bio_38': '',
    'bio_39': 'Президент Қасым-Жомарт Кемелұлы Тоқаевтың 2019-2022 жылдар аралығындағы басқа бастамаларымен танысу:',
    'programs_title': 'Сайлауалды бағдарлама',
    'headquarters_title': 'Республикалық қоғамдық штаб',
    'video_title': 'Видео үндеу',
    'nakaz2_title': 'Хат жазу',
    'rosh_title': 'Команда',
    'video_footer': 'Видео үндеу',
    'president_letter': 'Хат',
    'district_placeholder': 'Аймақты таңдау',
    'thx_video': 'Видеоны жібергеніңізге рақмет',
    'import_link': 'Сілтемені қойыңыз',
    'on_moderation': 'Сіздің видеоңыз өңделіп жатыр',
    'to_moderation': 'Модерацияға жіберіңіз',
    'date_1': '1975-1979 жылдар',
    'date_2': '1983-1991 жылдар',
    'date_3': '1992 жыл',
    'date_4': '1992-1994 жылдар',
    'date_5': '1996 жылы',
    'date_6': '2011-2013 жылдар',
    'date_7': '9 маусым 2019 жыл',
    'date_8': '2019-2022 жж. барлық нәтижені көріңіз',
    'staffs': 'Аймақтық штабтар',
    'up': 'Жоғары',
    'age': 'Жасыңыз',
    'all': 'Барлығын көру',
    'fio_short': 'Аты-жөні',
    'position': 'Лауазымы',
    'modal_text': 'Құрметті сайлаушылар!\n' +
        '\n' +
        'Президенттікке үміткер Қасым-Жомарт Кемелұлы Тоқаевтың бағдарламасына қызығушылық танытқаныңыз үшін алғыс білдіреміз.\n' +
        ' Бағдарламаның мазмұны 2022 жылдың 27 қазанынан бастап қолжетімді болады.\n' +
        ' \n' +
        'Назарларыңызға рахмет!',
    'staff_2': 'Қазақстан Республикасының президенттігіне үміткер Қасым-Жомарт Тоқаевтың қоғамдық штабының өз облысыңыздағы өкілінің мекенжайы мен байланыс номерін осы жерден таба аласыз.',
    'staff_3': 'Аймақтық штабтар',
    'staff_4': 'Уәкілетті тұлға',
    'staff_6': 'Олардың әрқайсысы үгіт-насихат науқанына белсенді қатысып, Қасым-Жомарт Тоқаевтың сайлауалды бағдарламасын түсіндіреді.',
    'send_own_video': 'Өз видеоңызды жіберіңіз',
    'work_results': 'Жемісті жылдар жылнамасы',
    'media': 'Медиа',
    'rosh_main_1': 'Қасым-Жомарт Тоқаев мырзаның саяси тұлғасы күн сайын Әділетті Қазақстанды бірге құруға дайын отансүйгіш азаматтардың басын біріктірді.',
    'rosh_main_2': 'Өз саласыңда көшбасшы болып табылатын 450 ден астам Қазақстан азаматы үгіт-насихат кампаниясына белсенді қатысып, Қасым-Жомарт Тоқаевтың сайлауалды бағдарламасын түсіндірумен айналысып отыр.',
    'rosh_main_button': 'Барлығын қараңыз',
    'senten-1': 'Қолдау',
    'senten-2': 'Ұсыныс',
    'senten-3': 'Тілекхат',
    'all_prog_2022': 'Толық мәтінін қараңыз',
    'not_found': '404 not found',
    'not_found_description': '404 description',
    'close': 'Жабу',
    'more_descr': 'толығырақ',
    'program_new_title': '<strong>Құрметті отандастар!</strong>',
    'program_new_text':
        '<p>Еліміз <strong>жаңа тарихи кезеңге</strong> аяқ басты.</p>' +
        '<p>2022 жылдың 20 қарашасында Қазақстан Республикасы <strong>Президентінің сайлауы</strong> өтеді. Бұл – біздің болашақ дамуымыздың стратегиялық <strong>бағытын айқындайтын науқан.</strong> </p>' +
        '<p>Парламенттік партиялар мен ірі қоғамдық ұйымдарды жұмылдырған <strong>Халық коалициясы</strong> мені Президент үміткерлігіне ұсынды.  Осыған байланысты еліміздің алдағы жеті жылдық кезеңдегі даму барысы туралы пайымымды ортаға салғым келеді.</p>' +
        '<p>Ең алдымен экономика, әлеуметтік сала мен өңірлік саясаттағы түбегейлі <strong>өзгерістер туралы</strong> бастама көтермекпін. Алдағы реформалар <strong>халықтың мұң-мұқтажына тиімді жауап</strong> болмақ, сонымен бірге сыртқы сын-қатерлерге қарсы тұрақтылығымызды күшейтпек.</p>' +
        '<p>Біздің басты мақсатымыз – <strong>Қазақстанда әділеттілік орнату.</strong> Ол үшін өткеннен сабақ алып, <strong>өткеннің қателігін түзетіп</strong>, қордаланған мәселелерді шешу керек.</p>' +
        '<p>Мемлекет монополияларды жояды, <strong>табыстың әділ бөлінуін</strong> қамтамасыз етеді. Қолынан іс келетін еңбектенем деген әр азамат өзіне лайықты <strong>жұмыс</strong> табады. Көмекке мұқтаж кез келген азамат мемлекет пен қоғамнан <strong>қолдау көреді.</strong></p>' +
        '<p>Біз <strong>ауызсу</strong> тапшылығы мәселесін біржола шешеміз, <strong>азық-түлік қауіпсіздігін</strong> күшейтеміз. Мемлекет ауыл тұрғындарын <strong>кәсіби медицинамен</strong> қамтамасыз етеді.</p>' +
        '<p>Мемлекет өскелең ұрпаққа білім беру үшін осы салаға инвестиция құя береді. Білім саласының барлық деңгейінде қордаланған мәселе шешіледі. Бала <strong>тәрбиесі мен олардың алаңсыз өмірі</strong> біздің жіті назарымызда болады.</p>' +
        '<p>Ауылда өмір сүру, жұмыс істеу қаладан еш кем болмайтындай етіп, <strong>ауылшаруашылығын</strong> қайта жандандырамыз. Фермерлер экономиканы ілгерілетуші жаңа күшке айналады.</p>' +
        '<p>Ел тұрғындары мен қонақтары өзін қала мен ауылда <strong>бірдей жайлы</strong> сезінуі үшін <strong>көше</strong> қауіпсіздігін қамтамасыз етеміз.</p>' +
        '<p>Біз ұлттық мүддені қорғауды, барлық мүдделі мемлекетпен өзара тиімді ынтымақтастықты нығайтуды көздейтін, халықаралық бейбітшілік пен қауіпсіздікті қамтамасыз ететін сыртқы саяси бағытты жалғастыра береміз.</p>' +
        '<p>Біз өзара байланысты үш қағидат негізіндегі жаңа мемлекеттік саясатқа көшеміз: \n<strong>ӘДІЛЕТТІ МЕМЛЕКЕТ– ӘДІЛЕТТІ ЭКОНОМИКА – ӘДІЛЕТТІ ҚОҒАМ.</strong></p>' +
        '<p>Барлық шешімді екшеп, ашық әрі әділ, ең бастысы - қоғам мүддесі мен талабын ескере отырып қабылдаймыз.  Сондықтан <i>бірінші қағидат</i> <strong>саяси жаңғыру үдерісін жалғастыруды</strong>, мемлекеттік және корпоративтік кәсіби басқаруды енгізуді көздейді. Үкімет, әкімдік, сот, прокуратура мен полиция қызметі азаматтың әл-ауқаты мен қауіпсіздігін қамтамасыз ету сияқты негізгі міндеттерді шешуге бағытталады.</p>' +
        '<p><i>Екінші қағидат</i> азаматтар, бизнес және мемлекет <strong>мүддесі мен игілігі теңгеріміне</strong>, сондай-ақ <strong>ел ресурсын</strong> тиімді пайдалануға негізделген <strong>экономика</strong> құруды білдіреді. Сол үшін қоғамның жұмыспен жоғары деңгейдегі қамтылуын, азаматтардың әлеуметтік ауызбірлігін, сонымен бірге теңгерімді аумақтық дамуды қамтамасыз ететін <strong>өнімді еңбек нарығы</strong> құрылады.</p>' +
        '<p><i>Үшінші қағидат</i> <strong>дамыған әлеуметтік инфрақұрылым</strong> жасауды және адам әлеуетін іске асыру үшін жан-жақты мүмкіндік беруді көздейді. Біз <strong>саламатты</strong>, білімді, <strong>еңбексүйгіш</strong>, әлеуметтік қорғалған және <strong>отаншыл</strong> қоғам қалыптастырамыз.</p>' +
        '<p>Аталған өзгерістің бәрін <strong>жақын арада</strong> жүзеге асыруға ниеттімін. <strong>Халық қолдауы</strong> осы <strong>мақсаттарға жетудің басты кепілі</strong> болады. Көзделген игі өзгерістерді іске асыруға <strong>неғұрлым көп</strong> азамат қатысса, <strong>нәтижеге</strong> соғұрлым тезірек <strong>жетеміз</strong>.</p>' +
        '<p>Қазақстан азаматтарының жақсы өмір сүргенін қалаймын. Мұны халқымыз түгел қолдайтыны маңызды.</p>',
    'support_link': 'Қолдау қызметіне хабарласыңыз',
    'support_title': 'Қолдау қызметі',
    'support_text': 'Сайт және оның мазмұны бойынша сұрақтар немесе мәселелер туындаған жағдайда бізге жазыңыз.',
    'fio_placeholder_s': 'Аты-жөні',
    'email_placeholder_s': 'E-mail адресіңіз',
    'text_placeholder_s': 'Сіздің хатыңыз',
    'write_placeholder': 'Жазу',
    'send_support': 'Жіберу',
    'support': 'Қолдау қызметі',
    'pr_1': 'ӘДІЛЕТТІ ҚАЗАҚСТАН ҮШІН',
    'pr_2': 'ҚУАТТЫ ҚАЗАҚСТАН ҮШІН',
    'pr_3': 'ОТАНЫМЫЗ — БІР, МЕМЛЕКЕТІМІЗ — БІР, ХАЛҚЫМЫЗ — БІР!',
    'pr_4': 'ҚУАТТЫ ҚАЗАҚСТАН — БІРТҰТАС ҰЛТ ',
    'pr_5': 'ӘДІЛЕТТІ ҚАЗАҚСТАН: ЕЛ БАЙЛЫҒЫ ХАЛЫҚҚА ',
    'pr_6': 'ӘДІЛЕТТІ ҚАЗАҚСТАН — ӘР ОТБАСЫНА ҚАМҚОРЛЫҚ ',
    'pr_7': 'ҚУАТТЫ ҚАЗАҚСТАН — ЗАҢ, ТӘРТІП, ҚАУІПСІЗДІК ',
    'pr_8': 'ӘДІЛЕТТІ ҚАЗАҚСТАН —  АЛАҢСЫЗ ҚАРТТЫҚ ',
    'pr_9': 'ӘДІЛЕТТІ ҚАЗАҚСТАН — ТАБЫСТЫ ҰРПАҚ ',
    'pr_10': 'ӘДІЛЕТТІ ҚАЗАҚСТАН — ЖЕМҚОРЛЫҚСЫЗ ЕЛ ',
    'pr_11': 'ӘДІЛЕТТІ ҚАЗАҚСТАН — ТЕҢ МҮМКІНДІК ',
    'vote': '20-шы қараша күні дауыс беріңіз',
    'news_slide_1': '«Мені мемлекет пен халықтың ұзақмерзімді мүдделерін сенімді қамтамасыз етуге деген шынайы ұмтылыс жетелейді. Мен үшін Отанға қызмет етуден асқан құрметті де жауапты миссия жоқ. Билікті өзгерту кепілдіктері билікті монополиялау әрекеттеріне күшті тосқауыл болады»',
    'search': 'Іздеу',
    'news_meta': 'Cайлау 2022 үміткер Тоқаев Қ.К. жайлы жаңалықтар',
    'news_meta_description': 'Республика бойынша Қасым-Жомарт Тоқаевты қолдауға арналған 5 мыңнан астам үгіт-насихат шарасы өткізілді',
    'programs_meta': 'Қасым-Жомарт Тоқаевтың 2022 жылғы сайлауалды бағдарламасы',
    'programs_meta_description': 'Қасым-Жомарт Тоқаевтың сайлауалды бағдарламасы, жаңалықтар, өмірбаяны, штабы.',
    'bio_meta': 'Қасым Жомарт Тоқаевтың өмірбаяны',
    'bio_meta_description': 'Президент Қасым-Жомарт Тоқаев 1953 жылы 17 мамырда Алматы қаласында, зиялы қауым отбасында дүниеге келді.',
    'main_meta': 'Қазақстандағы сайлау 2022 Қ.Ж.Тоқаев',
    'main_meta_description': 'Сайлау 2022 Қасым-Жомарт Тоқаевтың сайлауалды бағдарламасы, жаңалықтар, өмірбаяны, штабы.',
    'headquarters_meta': 'Республикалық Қоғамдық Штабпен байланыс',
    'headquarters_meta_description': 'Қасым-Жомарт Тоқаевтың Республикалық Қоғамдық Штабы 2022',
    'close_text': '19 қарашада үгіт-насихат науқаны аяқталып, тыныштық күні жарияланады.'
};