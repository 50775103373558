export default {
    'ru': 'рус',
    'kk': 'қаз',
    'en': 'eng',
    'biography': 'Biography',
    'program': 'Program',
    'news': 'News',
    'contacts': 'Contacts',
    'main_title': '“I want a happy life for our citizens today, not tomorrow”',
    'fio_president': 'Kassym-Jomart Kemelovich Tokaev',
    'section2_title': 'We are building a Fair Kazakhstan - a country of equal opportunities for all.',
    'section2_description': 'Every citizen, regardless of place of residence, has the right to a decent life and full self-realization, because national wealth is created thanks to the work and efforts of all residents of the country.',
    'section3_title': 'The President and the government supported and quickly responded to every challenge.',
    'section3_link': 'View all work done',
    'stats_1': '<div>25%</div>\n' +
        '<div>Teacher salaries increased by 25%</div>\n' +
        '<div>The average salary of teachers in the country is 343, 000 KZT. An additional 25% increase is expected from January 2023.</div>',
    'stats_2': '<div>11 billion KZT</div>\n' +
        '<div>More than 11 billion KZT sent to charity</div>' +
        '<div>Fund "Kazakhstan Halkyna" provided charitable assistance to the victims of the January events, children with special needs and in need of treatment abroad</div>',
    'stats_3': '<div>1.7 million</div>\n' +
        '<div>1.7 million Kazakhstan people took advantage of pension savings</div>\n' +
        '<div>For the first time, it became possible to use pension savings to improve housing conditions and treatment</div>',
    'stats_4': '<div>3 years</div>' +
        '<div>SMEs are exempt from taxes for 3 years</div>' +
        '<div>A moratorium has been introduced on inspections of small and medium-sized businesses.</div>',
    'stats_5': '<div>2.9 million/ha</div>' +
        '<div>The earth and its bowels now belong to the nation!</div>' +
        '<div>2.9 million hectares of land were returned to the state fund. A ban on the sale of land to foreigners was adopted.</div>',
    'stats_6': '<div>700 akims</div>' +
        '<div>Election of akims</div>' +
        '<div>For the first time on July 25, 2021, elections were held for more than 700 rural akims</div>',
    'section4_title': 'Justice in all its manifestations is a solid foundation of our statehood!',
    'section4_description': '<p>The future President is obliged to do everything necessary so that every citizen of Kazakhstan has a decent job and fair payment for work.</p>' +
        '<p>To ensure that every citizen enjoys quality public services. Has access to modern medicine. Receives an advanced education. Fully protected by law. Confident lookes to the future.</p>',
    'medicine': 'The medicine',
    'medicine_descr': 'The healthcare industry needs a lot of funding. It includes infrastructure, personnel, drug provision.',
    'education': 'Education',
    'education_descr': 'Kazakhstani education and science faces a large-scale, urgent task - not just to keep up with new trends, but to be one step ahead, to generate trends.',
    'social': 'Social protection',
    'social_descr': 'Kazakhstan is a social state. Comprehensive help to citizens in difficult situations is one of our priorities.',
    'security': 'Safety',
    'security_descr': 'There is a massive war going on right next to us. With this in mind, we must first of all think about the security of the country.',
    'regional': 'Regional development',
    'regional_descr': 'The main principle of the “hearing state” is that the state apparatus should work in the interests of citizens. This is especially true for local governments.',
    'economy': 'Economy',
    'economy_descr': 'Our strategic goal is to strengthen our leading role in Central Asia and our position in the global economy.',
    'all_program': 'See the full program',
    'all_program_descr': 'Effective public administration Affordable housing, clean environment ...',
    'new_initiative_descr': 'Write your own initiative for the development of Kazakhstan',
    'new_initiative': 'Write initiative',
    'section5_title': 'We are faced with grandiose national tasks.',
    'section5_descr': 'I declare my election program open. I will collect orders from all over the country and I am ready to include constructive proposals in my program of work.',
    'section5_button': 'Submit your offer',
    'all_news': 'View all news',
    'place': 'Headquarters',
    'social_networks': 'Social networks:',
    'footer_text_1': 'Official website of the candidate for the President of the Republic of Kazakhstan K.K.Tokaev. Made by order of Ospanov M.Zh. at the expense of the electoral fund of the candidate for the President of the Republic of Kazakhstan K.K.Tokaev.',
    'footer_text_2': 'All campaign materials contained on this site were made by order of persons authorized by the candidate and paid for by the candidate\'s electoral fund.\'s electoral fund. AnyRead more...',
    'sign': '© 2022 Kassym-Jomart Tokayev',
    'slider2_1': 'We have withstood the pressure of new challenges and showed courage, unity and solidarity. In the toughest conditions, apart from solving daily issues, we also ensured the progressive development of the country',
    'slider2_1_sign': 'Kassym-Jomart K. Tokayev',
    'last': 'During the years of independence, we have worthily passed through all the difficulties and achieved great success. Thanks to our unity, we have defended our state. However, no matter how difficult the times, we will firmly stick to our course. On this path, we, first of all, need unity, common sense and patience, wisdom and endurance. No matter what happens, I will always be with my people.',
    'total_news_pl': 'Total {n} news :::Total {n} news :::Total {n} news',
    'main_page': 'Home',
    'all_news_page': 'All news',
    'recommended_news': 'Recommended news',
    'all_news_more': 'More news',
    'share': 'Share',
    'all_facts': 'All done work',
    'facts_title': 'We have to return the trust of citizens',
    'facts_description': 'convincing them with figures, facts, actions, promptly responding to criticism and constructive proposals from them',
    'recommended_facts': 'Recommended events',
    'check_all': 'view all',
    'nakaz_title': 'We will build an open state where society is involved in the decision-making process and makes the country strong.',
    'nakaz_description': 'Write to us, we are ready to conduct a constructive dialogue and respond to citizens requests in a timely manner.',
    'gender_m': 'Male',
    'gender_f': 'Female',
    'gender': 'Gender',
    'fio_placeholder': 'Full name',
    'send': 'Submit a Form',
    'select_living_place': 'Choose a place of residence',
    'select_living_type': 'Type of settlementа',
    'other': 'Other',
    'select_placeholder': 'Select',
    'string_length': 'Number of characters {n}',
    'working_type': 'Select the field of activity from the proposed list, and if there is no suitable one, then select Other:',
    'nakaz_theme': 'Select the topic of the mandate or the offer from the provided list, and if there is no suitable one, then please, select Other:',
    'nakaz_placeholder': 'Enter the text of the order or offer in a special window, then click “Submit a Form”.',
    'thx': 'Thank you!',
    'thx_descr': 'Thank you for submitting <br/> your offer.',
    'total_problems': '{n} people :::{n} of people',
    'program_new': 'Only with your support is it possible to implement fundamental changes to build Fair Kazakhstan.',
    'all_program_descr_1': 'Effective public administration',
    'all_program_descr_2': 'Affordable housing, clean environment ...',
    'new_initiative_descr_1': 'I have suggestions for the country development!',
    'footer_text_2_1': 'All campaigning materials contained on this site are made at the request of the persons authorized by the candidate and paid for by the electoral fund of the candidate.',
    'program_theme': 'Candidate appeal',
    'program_title': 'Pre-election Program of Candidate for President of the Republic of Kazakhstan Tokayev Kassym-Jomart Kemelevich',
    'program_title_small': 'Dear compatriots!',
    'program_text': '<p> We are a united nation of Kazakhstan. We share the common values of our sacred land: peace, prosperity, justice, equal opportunities for all. </p> <p> Our country has a great past. We live in a strong, sovereign state. We have something to protect, so we look bravely forward into the future. Our unity is unshakeable. </p>',
    'staff': 'Headquarter',
    'video_letter': 'Video massage',
    'completed_tasks': 'Completed tasks',
    'rosh': 'Republican public headquarter',
    'support_letter': 'Support letter',
    'back': 'Back',
    'main': 'Home',
    'completed_work': 'All done work',
    'comp_1': 'To organize the campaign of the Candidate for the President of the Republic of Kazakhstan, Kassym-Jomart Tokayev, the Republican Public Headquarters was formed.',
    'comp_2': 'The patriots of the whole country united around the political figure of Mr. Tokaev K.K., ready to build Fair Kazakhstan together every day.',
    'comp_3': 'Kassym-Jomart Tokayev explained the Election Program to the peers, who are actively participate in the campaign, in a language close to them.',
    'comp_4': 'If you are ready to support our social movement, upload your video letter on our page.',
    'send_video': 'If you are ready to support our social movement, upload your video letter on our page.',
    'all_init': 'All initiatives',
    'look': 'Look',
    'fact_1': 'The interests of the State are the interests of the people.',
    'fact_2': 'We will build a strong government that respects the principles of democracy, the rule of law and equality. In our independent country, a new, free-spirited generation is growing. We are making all fundamental changes for the sake of the future of the younger generation.',
    'fact_3': 'We have one Motherland, one country, one nation!',
    'fact_4': 'Chronicle of productive cases',
    'results': 'Results',
    'fact_5': 'The main goal of our reforms - the construction of Fair Kazakhstan',
    'staff_region': 'Choose a headquarters region',
    'leader': 'Leader',
    'send_2022': 'Message 2022-2029',
    'load_more': 'Load more',
    'problem_form_1': 'Open government is the governing form where society makes the country strong and is involved in decision-making.',
    'problem_form_2': 'Please write to us, we are ready to conduct a constructive dialogue and respond promptly to citizens\' requests.',
    'fio': 'Last name First name Middle name ',
    'phone_num': 'Phone number',
    'leave_place': 'Residence',
    'region_type': 'Type of settlement',
    'to_main': 'Back to home',
    'nakaz': 'Mandates',
    'form2_1': 'Send your letter to Presidential Candidate Kassym-Jomart Kemeluly Tokayev.',
    'form2_2': '',
    'form2_3': 'Residence',
    'region': 'Region',
    'district': 'District',
    'form2_4': 'Write a letter of support or gratitude personally to the presidential candidate Kassym-Jomart Kemeluly Tokayev.',
    'form3_1': 'Send a letter with suggestions or words of support to the presidential candidate',
    'already_send': 'already sent',
    'write_letter': 'Write a letter',
    'write': 'Write',
    'program_themes': 'Program sections',
    'program_1': 'Election Platform <p>FAIR KAZAKHSTAN – FOR ALL AND FOR EVERYONE NOW AND FOREVER</p>',
    'program_2': 'View program',
    'program_3': 'You can send your ideas for creating a Fair Kazakhstan',
    'program_4': 'Share an Idea',
    'vide_letter': 'Video massage',
    'video1_1': 'Publish your own video massage',
    'video2_2': 'Paste the youtube link with your video',
    'video_3': 'Incorrect link',
    'video_4': '<span>1. Paste the youtube link with your video; </span>\n' +
        '                <span>2. Open your profile for helping us to access the video;</span>\n' +
        '                <span>3. Video length is up to 1 minute;</span>\n' +
        '                <span>\n' +
        '                  4. It is forbidden to speak out on religious or interethnic grounds\n' +
        '                </span>',
    'all_video': 'Video messages to Kassym-Jomart Tokayev',
    'video3_1': 'Upload a video-appeal to support the presidential candidate',
    'video3_2': 'See all video appeals',
    'video3_3': 'Publish your videо',
    'bio_1': '',
    'bio_2': 'May 17, 1953',
    'bio_3': 'Kassym-Jomart Tokayev was born on May 17, 1953 in Alma-Ata (Almaty), grew up in a family of intellectuals.',
    'bio_4': 'Father – Kemel T. Tokayev (1923-1986), a veteran of the Great Patriotic War, a well-known writer, founder of a detective-adventure genre in Kazakh literature. Mother – Turar Shabarbayeva (1931-2000), worked for the Alma-Ata Teacher Training Institute of Foreign Languages.',
    'bio_5': 'SCHOOL AND STUDENT YEARS',
    'bio_6': '1970-1975 years',
    'bio_7': 'Kassym-Jomart Tokayev graduated from Almaty Secondary School 25 with in-depth study of French, where he was considered one of the best students.',
    'bio_8': 'In 1975 he completed his studies at the Moscow State Institute of International Relations (MGIMO MFA of the USSR). Student Kassym-Jomart Tokayev studied «excellently», although it was not easy to compete with the children of diplomats and statesmen for the guy from Kazakhstan.',
    'bio_9': '',
    'bio_10': '',
    'bio_11': 'After graduating from MGIMO, Tokayev began his career as a diplomat with the position of duty assistant in the Ministry of Foreign Affairs of the USSR. For his work he was seconded to the Soviet Embassy in Singapore.',
    'bio_12': 'In 1979, at the end of a four-year long worktrip abroad, he returned to the Ministry of Foreign Affairs of the USSR.',
    'bio_13': 'In 1983, Kassym-Jomart Tokayev spent a ten-month internship in China at the Beijing Linguistic Institute. Two years later, in 1985, he was sent as a diplomat to the Soviet Embassy in Beijing, where he worked until 1991 as a second secretary, first secretary and councellor.',
    'bio_14': 'In addition to his diplomatic activities, he competed in a table tennis tournament. Photo of young Kassym-Jomart Tokayev, taken in 1986 in Beijing. (photo)',
    'bio_15': '',
    'bio_16': '',
    'bio_17': 'In May 1992 Kazakhstan joined UNESCO. The first object of our country, included in the UNESCO list, was the Mausoleum of Khoja Ahmed Yassawi.',
    'bio_18': 'In the photo Kassym-Jomart Tokayev with UNESCO Director General Federico Majoor, May 1992, Paris. (photo)',
    'bio_19': 'After Kazakhstan gained independence, Kassym-Jomart Tokayev became Deputy of the First Minister of Foreign Affairs of the Republic of Kazakhstan, Tuleutai Suleimenov.',
    'bio_20': 'Kassym-Jomart Tokayev held the post of Minister of Foreign Affairs of the Republic of Kazakhstan for a total of 10 years. From 1994 to 1999 and from 2003 to 2007.',
    'bio_21': 'As Minister of Foreign Affairs, he made a significant contribution to the field of non-proliferation of nuclear weapons. In 1996, in the city of New York, at the 50th session of the UN General Assembly, Kassym-Jomart Tokayev signed the Comprehensive Nuclear Test Ban Treaty. In 2005, he signed the Treaty on the Establishment of a Nuclear-Weapon-Free Zone in Central Asia (CAWFZ) in the city of Semipalatinsk.',
    'bio_22': 'From the personal archive of Makat Sadik. 1998. Travel abroad. (photo)',
    'bio_23': 'From 1999 to 2002, Kassym-Jomart Tokayev worked as Deputy Prime Minister and later as Prime Minister of the Republic of Kazakhstan.',
    'bio_24': '2000. Kassym-Jomart Tokayev’s work visit to one of the country regions as head of the government. (photo)',
    'bio_25': 'His premiership coincided with a difficult period in the history of Independent Kazakhstan. Under the leadership of Kassym-Jomart Tokayev, the Government of the country was able to take a course for sustainable economic growth. As a result, the investment climate has improved and employment has increased. All this clearly shows how the question of the welfare of the people was a priority for Kassym-Jomart Tokayev.',
    'bio_26': 'SECRETARY OF STATE - MINISTER OF FOREIGN AFFAIRS OF THE REPUBLIC OF KAZAKHSTAN IN 2002-2003',
    'bio_27': 'While working, he met with US President George Bush, Jr.. (photo)',
    'bio_28': 'THE PRESIDENT OF THE SENATE OF THE PARLIAMENT OF THE REPUBLIC OF KAZAKHSTAN',
    'bio_29': 'Kassym-Jomart Tokayev headed the Senate of the Parliament of the Republic of Kazakhstan for more than 10 years. Over the years, he has developed a clear understanding of the legal mechanisms of statehood. All legal bases and various reforms of socio-economic development of Kazakhstan passed through Kassym-Jomart Kemeluly.',
    'bio_30': 'Hence the deep knowledge about the principles of the rule of law and the open state, which is mentioned so often by Kassym-Jomart Tokayev. It was during this period that the Parliament developed and adopted a significant package of legislative amendments that strengthened the democratic structure of the State.',
    'bio_31': 'Kassym - Jomart Tokayev worked as Deputy Secretary-General of the United Nations, head of the European Office of the United Nations in Geneva. He was the personal representative of UN Secretary General Ban Ki-moon to the Conference on Disarmament.',
    'bio_32': 'Kassym-Jomart Tokayev and Ban Ki-moon. (photo)',
    'bio_33': 'He served as Secretary-General of the Conference on Disarmament. (photo)',
    'bio_34': 'September 13, 2012. US Secretary of State John Kerry and Russian Foreign Minister Sergey Lavrov, accompanied by UN Office in Geneva Director General Kassym-Jomart Tokayev at a meeting on making a political decision on the civil war in Syria. (photo)',
    'bio_35': '',
    'bio_36': 'Kassym-Jomart Tokayev won the special presidential election, gaining 70.96% and was elected as the President of the Republic of Kazakhstan.',
    'bio_37': 'One of the first decrees of the President deals with the social protection of the population. It is about debt relief for people who find themselves in difficult circumstances. <br /> It instructed the Government and the National Bank to pay off the debts of large families and families with children with disabilities or one breadwinner. This initiative affected 3 million citizens or 16% of the population.',
    'bio_38': '',
    'bio_39': 'Get acquainted with other initiatives of President Kassym-Jomart Kemeluly Tokayev in the period from 2019-2022:',
    'programs_title': 'Election program',
    'headquarters_title': 'Republican Community Headquarters',
    'video_title': 'Video message',
    'nakaz2_title': 'Write a letter',
    'rosh_title': 'Team',
    'video_footer': 'Video message',
    'president_letter': 'Letter',
    'district_placeholder': 'Region Selection',
    'thx_video': 'Thank you for your video',
    'import_link': 'Paste the Link',
    'on_moderation': 'Your video is on moderation',
    'to_moderation': 'Submit the video',
    'date_1': '1975 - 1979 years',
    'date_2': '1983 - 1991 years',
    'date_3': '1992 year',
    'date_4': '1992-1994 years',
    'date_5': 'In 1996',
    'date_6': '2011-2013 years',
    'date_7': 'June 9, 2019',
    'date_8': 'Show all results for <br /> 2019-2022',
    'staffs': 'Headquarters',
    'up': 'Back to top',
    'age': 'Age',
    'all': 'All',
    'fio_short': 'Full name',
    'position': 'Position',
    'modal_text': 'Dear electors, \n' +
        'We are grateful to you for the interest in the program of the presidential candidate - Kassym-Jomart Kemeluly Tokayev. The contents of the program will be available from October 27, 2022.\n' +
        '\n' +
        'Thank you for the understanding!',
    'staff_2': 'Find the address and contacts of the Presidential Candidate\'s public headquarters representative in your region.',
    'staff_3': 'Regional headquarters',
    'staff_4': 'Trustees',
    'staff_6': ' Each of them actively participates in the campaign, promoting and explaining the Election Program of Kassym-Jomart Tokayev.',
    'send_own_video': 'Submit your video',
    'work_results': 'President\'s results',
    'media': 'Media',
    'rosh_main_1': 'Patriots of the country united around the political figure of Mr. Tokayev K.K., ready to build a just Kazakhstan together every day.',
    'rosh_main_2': 'More than 450 people from all over Kazakhstan, being leaders in their fields, actively participate in the campaign, explain the election program of Kassym-Jomart Tokayev.',
    'rosh_main_button': 'View all',
    'senten-1': 'Support',
    'senten-2': 'Suggestions',
    'senten-3': 'Wishes',
    'all_prog_2022': 'View the full version',
    'not_found': '404 not found',
    'not_found_description': '404 description',
    'close': 'Close',
    'more_descr': 'details',
    'program_new_title': 'Dear Compatriots!',
    'program_new_text':
        '<p>Our country is entering a <u><strong>new historical period.</strong></u></p>' +
        '<p><u><strong>The presidential election</strong></u> of the Republic of Kazakhstan will be held on November 20th, 2022. It will <u><strong>determine the strategic course</strong></u> of our further development.</p>' +
        '<p>The <strong>people\'s coalition</strong>, which united parliamentary parties and large public organizations, nominated me as a candidate for Presidency. In this regard, I propose my vision of the development of Kazakhstan for the next seven years.</p>' +
        '<p>First of all, I plan to initiate fundamental changes in the economy, social sphere, and regional policy. The reforms will <strong>be an effective response to the aspirations</strong> of the people and will ensure our resilience to the extreme pressure of external challenges.</p>' +
        '<p>Our main goal is <u><strong>to build a Fair Kazakhstan</strong></u>. To achieve that, it is necessary <u><strong>to consider and correct the mistakes of the past</strong></u>, solve the accumulated problems.</p>' +
        '<p>The state will put an end to monopolies and ensure a <u><strong>fair distribution of income</strong></u>. Everyone who wants and can work will find <strong>a decent employment</strong>. Everyone who needs help <strong>will be supported by the state and society.</strong></p>' +
        '<p>We will completely solve the problem of the shortage of clean water, strengthen food security. The state will provide rural residents with professional medical services.</p>' +
        '<p>The state will invest in the education of <strong>the younger generation.</strong> Problems at all levels of education will be resolved. Our focus will be on the upbringing of children and their well-being.</p>' +
        '<p>We <strong>will revive the agricultural sector</strong> so that living and working in rural areas is no less prestigious than in big cities. Farmers will become a new driving force for the development of our economy.</p>' +
        '<p>We will ensure the security on the streets so that residents and guests of the country feel equally comfortable both in cities and in rural areas.</p>' +
        '<p>We will continue our <strong>foreign policy course</strong> aimed at protecting national interests, strengthening mutually beneficial cooperation with all interested states, and ensuring international peace and security.</p>' +
        '<p>We will move on to <strong>a new state policy</strong>, which will be based on three interrelated principles: \n<strong>FAIR STATE – FAIR ECONOMY – FAIR SOCIETY</strong>.</p>' +
        '<p>All decisions will be made in a <strong>balanced, open and fair</strong> manner, and most importantly by taking into account the interests and demands of society. Therefore, <i>the first principle</i> provides for the <u><strong>continuation of political modernization</strong></u>, the introduction of professional state and corporate governance. The actions of government, akimats, courts, prosecutors’ office and police will be aimed at solving the main task, which is to ensure the well-being and safety of citizens.</p>' +
        '<p><i>The second principle</i> means building an <u><strong>economy</strong></u> based on <u><strong>a balance of interests and well-being</strong></u> of citizens, business and the state, as well as the efficient use of the country\'s resources. To this end, a modern <u><strong>productive labor market</strong></u> will be created, providing high employment and social cohesion of people, balanced territorial development.</p>' +
        '<p><i>The third principle</i> presupposes <strong>the creation of a developed social infrastructure</strong> and ample opportunities for realizing human potential. We will build a healthy, educated, hardworking, socially protected and patriotic society.</p>' +
        '<p>I intend to implement all this <u><strong>in the very near future</strong></u>. The support of the people will be <u><strong>the main factor in achieving these goals</strong></u>. The <u><strong>more citizens</strong></u> will be involved in the implementation of the planned transformations, the faster we <u><strong>will get the result</strong></u>.</p>' +
        '<p>I am sincerely interested in seeing the lives of Kazakhstan citizens improve. It is important that this aspiration is shared by the <u><strong>whole nation</strong></u>.</p>' +
        '',

    'support_link': 'Service support',
    'support_title': 'Service support',
    'support_text': 'If you have any questions or concerns about the website and/or its content, email us',
    'fio_placeholder_s': 'Write your name',
    'email_placeholder_s': 'Write your email',
    'text_placeholder_s': 'Your message ',
    'write_placeholder': 'Write',
    'send_support': 'Submit',
    'support': 'Service support',
    'pr_1': 'FOR FAIR KAZAKHSTAN',
    'pr_2': 'FOR STRONG KAZAKHSTAN ',
    'pr_3': 'WE HAVE ONE — HOMELAND, ONE — STATE, ONE — NATION!',
    'pr_4': 'STRONG KAZAKHSTAN — ONE NATION',
    'pr_5': 'FAIR KAZAKHSTAN: THE COUNTRY\'S WEALTH TO THE PEOPLE ',
    'pr_6': 'FAIR KAZAKHSTAN — CARE AND PROTECTION OF THE FAMILY ',
    'pr_7': 'STRONG KAZAKHSTAN: LAW, ORDER, SECURITY ',
    'pr_8': 'FAIR KAZAKHSTAN — DIGNIFIED OLD AGE',
    'pr_9': 'FAIR KAZAKHSTAN — SUCCESSFUL GENERATION',
    'pr_10': 'FAIR KAZAKHSTAN — COUNTRY WITHOUT CORRUPTION ',
    'pr_11': 'FAIR KAZAKHSTAN — EQUAL OPPORTUNITIES',
    'vote': 'Отдайте свой голос 20-го ноября',
    'news_slide_1': '"I am motivated by a sincere desire to reliably ensure the long-term interests of the state and people. There is no mission more honorable and responsible for me than serving my country. Guarantees of succession will become a powerful barrier to any attempts to monopolize power"',
    'search': 'Search',
    'news_meta': 'News Tokayev elections in Kazakhstan 2022',
    'news_meta_description': 'More than 5 thousand campaign events in support of Kassym-Jomart Tokayev took place throughout the country',
    'programs_meta': 'Tokayev\'s election program 2022',
    'programs_meta_description': 'Tokayev K.J. Presidential elections 2022: Tokayev\'s election program, news, biography, headquarters.',
    'bio_meta': 'Kasym Jomart Tokayev Biography of the President of the Republic of Kazakhstan',
    'bio_meta_description': 'President Kassym-Jomart Tokayev was born on May 17, 1953 in Alma-Ata (now Almaty), grew up in a family of intellectuals.',
    'main_meta': 'Kazakhstan elections 2022 K. J Tokayev',
    'main_meta_description': 'Tokayev K.J. Presidential elections 2022: Tokayev\'s election program, news, biography, headquarters.',
    'headquarters_meta': 'Contacts Republican Public Headquarters',
    'headquarters_meta_description': 'Republican public headquarters of Tokayev 2022',
    'close_text': 'On November 19, the agitation will end and a day of silence will be declared.'

};