<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="app">
    <router-view></router-view>
    <transition name="fade">
      <div id="pagetop" class="up" v-show="scY > 300" @click="toTop">
        {{ $t("up") }}
        <img src="./assets/img/up.svg" alt=""/>
      </div>
    </transition>
  </div>
</template>

<script>
import {setTokenToClient} from "@/_api";
import {LS_TOKEN} from "@/_types";

export default {
  name: "App",
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  computed: {
    getOgImage() {
      return window.location.origin + require('./assets/img/third-logo.jpg')
    }
  },
  mounted() {
    // var ogmetatag = document.createElement('meta');
    // ogmetatag.setAttribute('property', 'og:image');
    // ogmetatag.setAttribute('content', this.getOgImage());
    // document.head.appendChild(ogmetatag);


    if (this.$store.state.auth.authorized) {
      setTokenToClient(localStorage.getItem(LS_TOKEN));
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.up {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background: #e6fcff;
  border-radius: 50px;
  padding: 10px 15px 10px 20px;
  cursor: pointer;
  color: #00424c;
  font-size: 1rem;
  font-family: "500";
  display: flex;
  align-items: center;
  z-index: 111112;
  transition: 0.3s;

  img {
    margin-left: 5px;
  }

  &:hover {
    -webkit-box-shadow: 0px 5px 10px 2px rgba(16, 53, 58, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(16, 53, 58, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(16, 53, 58, 0.2);
  }
}
</style>
