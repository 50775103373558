<template>
    <div class="dialog" v-if="modalVisible">
        <div class="card">
            <slot id="modal">
            </slot>
        </div>
        <div class="overlay" @click="hideModal"></div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    data() {
        return {
            modalVisible: false
        };
    },
    methods: {
        showModal() {
            this.modalVisible = true;
        },
        hideModal() {
            this.modalVisible = false;
            this.$emit('closed');
        },
    },
};
</script>

<style>
.dialog {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    left: 0 !important;
    z-index: 9999999;
}
.card{
    width: 688px;
    max-height: 90vh;
    /*background: #fff;*/
    overflow-y: scroll;
    border-radius: 16px;
    right: -17px;
    /*padding-bottom: 0;*/
}
.card::-webkit-scrollbar {
    display: none;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
</style>