<template>
  <div v-if="currentLocale">
    <Header
      v-if="$route.name != 'teh'"
      @scrollTo="scrollTo()"
    ></Header>
    <router-view />
    <Footer
      v-if="

        $route.name != 'teh' &&
        $route.name != 'programs'
      "
      @scrollTo="scrollTo()"
    ></Footer>
  </div>
</template>

<script>
import { checkLocale } from "@/router/router-guards";
import i18nService from "../_services/i18n.service";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: { Footer, Header },
  computed: {
    currentLocale() {
      return this.$route.params.locale;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.locale !== from.params.locale) {
        this.setLocale(to.params.locale);
        // window.location.reload();
      }
    },
  },
  mounted() {
    this.setLocale(this.$route.params.locale);
  },
  methods: {
    setLocale(locale) {
      i18nService.setCurrentLocale(locale);
    },
    scrollTo() {
      document.getElementById("scroll_to_program").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  beforeRouteUpdate: checkLocale,
};
</script>
