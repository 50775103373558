<template>
  <div class="language">
    <div
      class="language__this"
      :class="{ language__active: visible }"
      @click="handleClick()"
    >
      <span>{{ $t(currLocale) }}</span>
      <img src="@/assets/img/arrow-right.svg" alt="" />
    </div>
    <div class="language__dropdown" v-if="visible">
      <div
        :class="{ dropdown__active: currLocale == 'kk' }"
        @click="setLocale('kk')"
      >
        {{ $t("kk") }}
      </div>
      <div @click="setLocale('ru')">{{ $t("ru") }}</div>
      <div @click="setLocale('en')">{{ $t("en") }}</div>
    </div>
  </div>
</template>

<script>
import i18nService from "@/_services/i18n.service";

export default {
  name: "Language",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleClick() {
      this.visible = !this.visible;
    },
    setLocale(lang) {
      i18nService.setCurrentLocale(lang);
      this.$router.replace({name: this.$route.name, params: { locale: lang }})
      this.handleClick()
      // this.$router.push({ name: this.$route.name, params: { locale: lang } });
    },
  },
  computed: {
    currLocale() {
      return this.$route.params.locale;
    },
  },
  created() {
    // window.addEventListener('click', (e) => {
    //     if (!this.$el.contains(e.target)) {
    //         this.hidePopover();
    //     }
    // });
  },
  mounted() {

  }
};
</script>

<style scoped></style>
