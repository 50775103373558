<template>
  <div class="header-height">
    <header class="header">
      <div class="container">
        <div class="header__inner">

          <div
            class="logo"
            @click="routeTo('main')"
            style="cursor: pointer"
          >
            <img src="@/assets/img/logo.png" alt="" />
          </div>
          <div>
          </div>
          <div class="header__wrap">
            <nav class="nav">
              <a
                v-for="link in allRoutes.filter((x) => x.is_burger == false&&x.condition==true)"
                :key="'head_' + link.name"
                href="#"
                @click.prevent="routeTo(link.name)"
                >{{ link.title }}</a
              >
            </nav>
            <div class="social">
              <img
                src="@/assets/img/telegram-desktop.svg"
                alt=""
                @click="toTelegram()"
              />
              <img src="@/assets/img/instagram.svg" alt="" @click="toInst()" />
              <img
                src="@/assets/img/facebook.svg"
                alt=""
                @click="toFacebook()"
              />
            </div>
            <language />
          </div>

          <div class="header__burger-menu">
            <language style="cursor: pointer"/>
            <img @click="clickButton" src="@/assets/img/burger.svg" alt="" />
          </div>
        </div>
      </div>
    </header>
    <section class="burger" v-if="toggleBurger">
      <div class="burger__inner">
        <div class="burger__header">
          <div class="container">
            <div class="burger__header-inner">
              <div class="logo" @click="routeTo('main')">
                <img src="@/assets/img/logo.png" alt="" />
              </div>
              <div class="burger__close" @click="toggleBurger = false">
                <img src="@/assets/img/burger-close.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="burger__items">
          <div class="burger__item" v-for="item in allRoutes.filter(x=>x.condition==true)" :key="item.name">
            <a href="" @click.prevent="routeTo(item.name)">{{ item.title }}</a>
          </div>

          <div class="burger__item">
            <div class="burger__socials">
              <img
                src="@/assets/img/facebook-mob.png"
                alt=""
                @click="toFacebook()"
              />
              <img
                src="@/assets/img/mob-instagram.svg"
                alt=""
                @click="toInst()"
              />
              <img
                src="@/assets/img/telegram-mobile.svg"
                alt=""
                @click="toTelegram()"
              />
              <img
                src="@/assets/img/mob-youtube.svg"
                alt=""
                @click="toYoutube()"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Language from "@/components/Language";

export default {
  name: "Header",
  components: { Language },
  data() {
    return {
      toggleBurger: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        this.toggleBurger = false;
      }
    },
  },
  methods: {
    toFacebook() {
      window.open("https://www.facebook.com/Toqaev2022", "_blank");
    },
    toInst() {
      window.open("https://www.instagram.com/toqaev2022/", "_blank");
    },
    toYoutube() {
      window.open(
        "https://youtube.com/channel/UCFSxyV97uoGMR4ztETM66vw",
        "_blank"
      );
    },
    toTelegram() {
      window.open("https://t.me/toqaev2022", "_blank");
    },
    clickButton() {
      this.toggleBurger = !this.toggleBurger;
    },
    routeTo(name) {
      if (this.$route.name==name){
        window.scrollTo(0,0)
      }else
        this.$router.push({ name: name });
    },
  },
  computed: {
    allRoutes() {
      return [
        {
          name: "bio",
          title: this.$t("biography"),
          is_burger: false,
          condition:true
        },
        {
          name: "programs",
          title: this.$t("program"),
          is_burger: false,
          condition: true
        },
        {
          name: "news",
          title: this.$t("news"),
          is_burger: false,
          condition:true
        },
        {
          name: "nakaz",
          title: this.$t("nakaz"),
          is_burger: true,
          condition:false
        },
        {
          name: "headquarters",
          title: this.$t("contacts"),
          is_burger: false,
          condition:true
        },
        {
          name: "video",
          title: this.$t("video_letter"),
          is_burger: true,
          condition:true
        },
        {
          name: "facts",
          title: this.$t("completed_tasks"),
          is_burger: true,
          condition:true
        },
        {
          name: "nakaz2",
          title: this.$t("support_letter"),
          is_burger: true,
          condition:false
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.header-height {
  height: 4.625rem;
}

@media (max-width: 767px) {
  .burger__header {
    /*padding: 25px 24px 12px 24px!important;*/
    /*padding-left: 24px!important;*/
    /*padding-right: 24px!important;*/
  }
  .header-height {
    height: 87px;
  }
  .burger__socials {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.slick-arrow {
  z-index: 1000 !important;
}

.header__burger-menu {
  align-items: center;

  .language {
    margin-right: 30px;

    &__this {
      background: #e6fcff;
      border-radius: 8px;
      padding: 8px 15px;
      color: #00424c !important;

      img {
        width: 20px;
        margin-left: 13px;
        height: 20px;
      }
    }

    &__dropdown {
      margin-top: 10px;
      position: absolute;
      width: 87px;
      background: #a1e2eb;
      border-radius: 8px;
      padding: 13px 16px;
    }
  }
}
@media (max-width: 767px) {
  .header__burger-menu {
    display: flex !important;
  }
}

@media (max-width: 1199px) {
  .header__burger-menu {
    display: flex !important;
  }
}

.burger__socials {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    & + img {
      margin-left: 34px;
    }
  }
}
</style>
