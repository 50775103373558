export default [
    {
        path: '/',
        name: 'main',
        props: true,
        component: () => import('../views/Home.vue'),
        meta: {
            title: 'main_meta',
            description: 'main_meta_description',
        }
    },
    {
        path: 'teh',
        name: 'teh',
        props: false,
        component: () => import('../views/Teh'),
        meta: {
            title: 'teh'
        }
    },
    {
        path: 'news',
        name: 'news',
        props: false,
        component: () => import('../views/news/News'),
        meta: {
            title: 'news_meta',
            description: 'news_meta_description'
        }
    },
    {
        path: 'news/:id',
        name: 'news-item',
        props: false,
        component: () => import('../views/news/NewsItem'),
        meta: {
            title: 'news'
        }
    },
    {
        path: 'facts',
        name: 'facts',
        props: true,
        component: () => import('../views/fact/Facts'),
        meta: {
            title: 'all_init'
        }
    },
    {
        path: 'facts/:id',
        name: 'fact-item',
        props: true,
        component: () => import('../views/fact/FactItem'),
        meta: {
            title: 'all_init'
        }
    },
    {
        path: 'nakaz',
        name: 'nakaz',
        props: false,
        component: () => import('../views/problems/ProblemForm'),
        meta: {
            title: 'nakaz'
        }
    },
    {
        path: 'complains',
        name: 'nakaz2',
        props: false,
        component: () => import('../views/problems/ProblemForm2'),
        meta: {
            title: 'nakaz2_title'
        }
    },
    {
        path: 'programs',
        name: 'programs',
        props: true,
        component: () => import('../views/program/Program'),
        meta: {
            title: 'programs_meta',
            description: 'programs_meta_description',
        }
    },
    {
        path: 'rosh',
        name: 'rosh',
        props: false,
        component: () => import('../views/facemarket/Facemarket'),
        meta: {
            title: 'rosh_title'
        }
    },
    {
        path: 'video',
        name: 'video',
        props: false,
        component: () => import('../views/video/VideoIndex'),
        meta: {
            title: 'video_title'
        }
    },
    {
        path: 'headquarters',
        name: 'headquarters',
        props: false,
        component: () => import('../views/headquarters/Headquarters'),
        meta: {
            title: 'headquarters_meta',
            description: 'headquarters_meta_description',
        }
    },
    {
        path: 'bio',
        name: 'bio',
        props: false,
        component: () => import('../views/bio/Bio'),
        meta: {
            title: 'bio_meta',
            description: 'bio_meta_description',
        }
    },

];
