<template>
  <div></div>
</template>

<script>
import requestService from "@/_services/request.service";
import catoJson from '@/json/cato.json'
import i18nService from "@/_services/i18n.service";

export default {
  name: "Common",
  data() {
    return {
      isReady: false,
      nakazReady: false,
      statTagsReady: false,
      roshReady: false,
      data: {},
      cato: catoJson
    };
  },
  methods: {
    getData() {
      this.data = {...this.importCatoJson()}
      // requestService.getData().then(res => {
      //
      //
      //   this.data.working_types = res.working_types.map(x=>{
      //     x.ru_name = x.translations.find(y=>y.locale == 'ru').name
      //     x.kk_name = x.translations.find(y=>y.locale == 'kk').name
      //     x.en_name = x.translations.find(y=>y.locale == 'en').name
      //     return x
      //   })
      //
      //   this.data.problem_theme = res.problem_theme.map(x=>{
      //     x.ru_name = x.translations.find(y=>y.locale == 'ru').name
      //     x.kk_name = x.translations.find(y=>y.locale == 'kk').name
      //     x.en_name = x.translations.find(y=>y.locale == 'en').name
      //     return x
      //
      //   })
      //
      //   this.isReady = true;
      //
      // });
    },
    getNakazData() {
      if (this.nakazReady == false) {
        requestService.getNakazData().then(res => {
          let tmp = {};
          tmp.working_types = res.working_types.map(x => {
            x.ru_name = x.translations.find(y => y.locale == 'ru').name
            x.kk_name = x.translations.find(y => y.locale == 'kk').name
            x.en_name = x.translations.find(y => y.locale == 'en').name
            return x
          })

          tmp.problem_theme = res.problem_theme.map(x => {
            x.ru_name = x.translations.find(y => y.locale == 'ru').name
            x.kk_name = x.translations.find(y => y.locale == 'kk').name
            x.en_name = x.translations.find(y => y.locale == 'en').name
            return x

          })
          tmp.cato_types = res.cato_types
          this.data = {...this.data, ...tmp}
          this.nakazReady = true;
        })
      }
    },
    getStats() {
      if (this.statTagsReady == false) {
        requestService.getStatTags().then(res => {
          this.data.stat_tags = res
          this.statTagsReady = true
        })
      }
    },
    getRosh() {
      if (this.roshReady == false) {
        requestService.getRoshTags().then(res => {
          this.data.facemarket_jobs = res;
          this.roshReady = true;
        })
      }
    },
    importCatoJson() {
      let regions = JSON.parse(JSON.stringify(this.cato)).filter(x => x.district == 0)
      regions.map(x => {
        if (i18nService.getCurrentLocale() == 'ru')
          x.name = x.ru_name
        else if (i18nService.getCurrentLocale() == 'kk')
          x.name = x.kk_name
        else
          x.name = x.en_name
        return x
      })

      let districts = JSON.parse(JSON.stringify(this.cato)).filter(x => x.district != 0)
      districts.map(x => {
        if (i18nService.getCurrentLocale() == 'ru')
          x.name = x.ru_name
        else if (i18nService.getCurrentLocale() == 'kk')
          x.name = x.kk_name
        else
          x.name = x.en_name
        return x
      })
      return {regions: regions, districts: districts}

    }
  },
  created() {
    this.getData()
  }
};
</script>

<style scoped>

</style>