import client, {handleResponse} from "@/_api/index";

const requestApi = {
    newsList(data) {
        return client.get('/api/posts', {params: data}).then(res => {
            return res.data;
        });
    },
    mainNews() {
        return client.get('/api/posts_main').then(res => {
            return res.data;
        });
    },
    getNewsItem(id) {
        return client.get('/api/post/' + id).then(res => {
            return res.data;
        });
    },
    getStats(data) {
        return client.get('/api/stats', {params: data}).then(res => {
            return res.data;
        });
    },
    getSlider(data) {
        return client.get('/api/slider', {params: data}).then(res => {
            return res.data;
        });
    },
    getStaff() {
        return client.get('/api/headquarters').then(res => {
            return res.data;
        });
    },
    getFacemarket(data) {
        return client.get('/api/facemarket', {params: data}).then(res => {
            return res.data;
        });
    },
    getVideos(data) {
        return client.get('/api/video', {params: data}).then(res => {
            return res.data;
        });
    },
    getFactItem(id) {
        return client.get('/api/stats/' + id).then(res => {
            return res.data;
        });
    },
    getData() {
        return client.get('/api/public_data').then(res => {
            return res.data;
        });
    },
    getPrograms() {
        return client.get('/api/programs').then(res => {
            return res.data;
        });
    },
    sendProblem(data) {
        return client.post('/api/problem', data).then(res => {
            return res.data;
        });
    },
    sendVideo(data) {
        return client.post('/api/video', data).then(res => {
            return res.data;
        });
    },
    login(email, password) {
        return client.post('/api/auth/login', {email, password}).then(handleResponse);
    },
    support(data) {
        return client.post('/api/support', data).then(res => {
            return res.data;
        })
    },
    getNakazData() {
        return client.get('/api/get_nakaz_data').then(res => {
            return res.data;
        })
    },
    getStatTags() {
        return client.get('/api/get_stat_tags').then(res => {
            return res.data;
        })
    },
    getRoshTags() {
        return client.get('/api/get_rosh_tags').then(res => {
            return res.data;
        })
    },

};
export default requestApi;