import axios from "axios";
import i18nService from "@/_services/i18n.service";
import router from "@/router";

const client = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL || 'http://localhost',
    headers: {
        'Content-Type': 'application/json',
        'X-Localization': i18nService.getCurrentLocale(),
    },
});

client.interceptors.response.use(res => res, error => {
    if (error.response.status === 500) {
        // Vue.prototype.$notyf.error({
        //     message: Vue.prototype.$t('server not responding'),
        // });
    } else if (error.response.status === 403 && error.response.data.message == 'no permission') {
        router.back();
    }
    throw error;
});


export function handleResponse(res) {
    return res.data;
}

export function handleError(error) {
    throw error;
}
export const setTokenToClient = token => {
    client.defaults.headers.common.Authorization = 'Bearer ' + token;
};

export default client;