export default {
    'ru': 'рус',
    'kk': 'қаз',
    'en': 'eng',
    'biography': 'Биография',
    'program': 'Программа',
    'news': 'Новости',
    'contacts': 'Контакты',
    'main_title': '“Я хочу счастливую жизнь наших граждан сегодня, а не завтра”',
    'fio_president': 'Қасым-Жомарт Кемелович Тоқаев',
    'section2_title': 'Мы строим Справедливый Казахстан – страну равных возможностей для всех.',
    'section2_description': 'Каждый гражданин вне зависимости от места проживания имеет право на достойную жизнь и полноценную самореализацию, потому что общенациональные богатства создаются благодаря труду и усилиям всех жителей страны.',
    'section3_title': 'Президент и правительство поддерживало и быстро реагировало на каждый вызов.',
    'section3_link': 'Посмотреть всю проделанную работу',
    'stats_1': '<div>25%</div>\n' +
        '<div>На 25% увеличилась заработная плата учителей</div>\n' +
        '<div>Средняя зарплата учителей по стране - 343 тысячи тенге. С января 2023 года ожидается дополнительное увеличение на 25%.</div>',
    'stats_2': '<div>11 млрд.тг</div>\n' +
        '<div>Более 11 млрд направили на благотворительную помощь</div>' +
        '<div>Фонд "Казахстан Халкына" оказал благотворительную помощь жертвам январских событий, детям с особенностями развития, детям нуждающимся в лечении зарубежом</div>',
    'stats_3': '<div>1,7 млн.</div>\n' +
        '<div>1,7 млн. казахстанцев воспользовались пенсионными накоплениями</div>\n' +
        '<div>Впервые появилась возможность воспользоваться пенсионными накоплениями для улучшения жилищных условий и лечения</div>',
    'stats_4': '<div>3 года</div>' +
        '<div>МСБ освобожден от налогов на 3 года</div>' +
        '<div>Введен мораторий на проверки малого и среднего бизнеса.</div>',
    'stats_5': '<div>2,9 млн/га</div>' +
        '<div>Земля и ее недра теперь принадлежат народу!</div>' +
        '<div>В государственный фонд возвращено 2,9 млн. гектаров земли. Принят запрет на продажу земель иностранцам.</div>',
    'stats_6': '<div>700 акимов</div>' +
        '<div>Выборность акимов</div>' +
        '<div>Впервые 25 июля 2021 года состоялись выборы более 700 сельских акимов</div>',
    'section4_title': 'Справедливость во всех ее проявлениях – прочный фундамент нашей государственности!',
    'section4_description': '<p>Будущий Президент обязан сделать все необходимое, чтобы у каждого казахстанца была достойная работа и справедливая оплата за труд.</p>' +
        '<p>Чтобы каждый гражданин пользовался качественными государственными услугами. Имел доступ к современной медицине. Получал передовое образование. Был полностью защищён законом. Уверенно смотрел в будущее.</p>',
    'medicine': 'Медицина',
    'medicine_descr': 'Сфера медицины нуждается в объемном финансировании. Речь идет об инфраструктуре, кадрах, лекарственном обеспечении.',
    'education': 'Образование',
    'education_descr': 'Перед казахстанским образованием и наукой стоит масштабная, неотложная задача – не просто поспевать за новыми веяниями, а быть на шаг впереди, генерировать тренды.',
    'social': 'Социальная защита',
    'social_descr': 'Казахстан – социальное государство. Всесторонняя помощь гражданам, оказавшимися в трудной ситуации, – один из наших приоритетов.',
    'security': 'Безопасность',
    'security_descr': 'В непосредственной близости от нас идет масштабная война. Помня об этом, мы должны в первую очередь думать о безопасности страны',
    'regional': 'Развитие регионов',
    'regional_descr': 'Главный принцип «слышащего государства» – госаппарат должен работать в интересах граждан. Это в первую очередь касается местных властей.',
    'economy': 'Экономика',
    'economy_descr': 'Наша стратегическая цель – усилить лидирующую роль в Центральной Азии и укрепить свои позиции в мировой экономике.',
    'all_program': 'Посмотреть программу',
    'all_program_descr': 'Эффективное государственное управление Доступное жилье, чистая экология ...',
    'new_initiative_descr': 'Написать свою инициативу для развития казахстана',
    'new_initiative': 'Написать инициативу',
    'section5_title': 'Перед нами стоят грандиозные по своему масштабу общенациональные задачи.',
    'section5_descr': 'Свою предвыборную программу объявляю открытой. Буду собирать наказы по всей стране и готов включить конструктивные предложения в программу своей работы.',
    'section5_button': 'Отправить своё предложение',
    'all_news': 'Посмотреть все новости',
    'place': 'Региональные штабы',
    'social_networks': 'Социальные сети:',
    'footer_text_1': 'Официальный сайт кандидата в Президенты Республики Казахстан К.К.Токаева. Изготовлен по заказу Оспанова М.Ж. за счет средств избирательного фонда кандидата в Президенты Республики Казахстан К.К.Токаева.',
    'footer_text_2': 'Все агитационные материалы, содержащиеся на этом сайте, изготовлены по заказу уполномоченных кандидатом лиц и оплачены средствами избирательного фонда кандидата.',
    'sign': '© 2022 Касым-Жомарт Токаев',
    'slider2_1': 'Мы выстояли перед напором новых вызовов. Проявили стойкость и солидарность. В сложнейших условиях решали не только повседневные задачи, но и обеспечивали поступательное развитие страны',
    'slider2_1_sign': 'Касым-Жомарт Кемелевич Токаев',
    'last': 'За годы Независимости мы достойно прошли через все трудности и достигли больших успехов. Только благодаря единству мы отстояли свое государство. Однако, какими бы тяжелыми ни были времена, мы будем твердо следовать своим курсом. На этом пути нам, в первую очередь, необходимо единство, холодный разум и терпение, мудрость и выдержка. Что бы ни случилось, я буду всегда со своим народом.',
    'total_news_pl': 'Всего {n} новость :::Всего {n} новости :::Всего {n} новостей',
    'main_page': 'Главная',
    'all_news_page': 'Все новости',
    'recommended_news': 'Рекомендуемые новости',
    'all_news_more': 'Подробнее новости',
    'share': 'Поделиться',
    'all_facts': 'Вся проделанная работа',
    'facts_title': 'Нам предстоит восстановить доверие граждан',
    'facts_description': 'убеждая их цифрами, фактами, действиями, оперативно реагируя на критику и конструктивные предложения граждан',
    'recommended_facts': 'Рекомендуемые события',
    'check_all': 'посмотреть всё',
    'nakaz_title': 'Мы построим открытое государство, где общество вовлечено в процесс принятия решений и делает страну сильной',
    'nakaz_description': 'Напишите нам, мы готовы вести конструктивный диалог и своевременно реагировать на запросы граждан.',
    'gender_m': 'Мужской',
    'gender_f': 'Женский',
    'gender': 'Пол',
    'fio_placeholder': 'Фамилия Имя Отчество',
    'send': 'Отправить',
    'select_living_place': 'Выбрать место жительства',
    'select_living_type': 'Тип населенного пункта',
    'other': 'Другое',
    'select_placeholder': 'Выбрать',
    'string_length': 'Количество символов: {n}',
    'working_type': 'Выберите сферу деятельности из предложенного списка, а если нет подходящей, то выберите Другое:',
    'nakaz_theme': 'Выберите тему наказа или предложения из предложенного списка, а если нет подходящей, то выберите Другое:',
    'nakaz_placeholder': 'Введите текст наказа или предложения в специальном окне затем нажмите “Отправить”.',
    'thx': 'Спасибо!',
    'thx_descr': 'Благодарим, что отправили <br/> своё предложение.',
    'total_problems': '{n} человек :::{n} человека',
    'program_new': 'Только при вашей поддержке возможна реализация системных мер по строительству Справедливого Казахстана.',
    'all_program_descr_1': 'Эффективное государственное управление',
    'all_program_descr_2': 'Доступное жилье, чистая экология ...',
    'new_initiative_descr_1': 'У меня есть инициативы по развитию Казахстана!',
    'footer_text_2_1': 'Все агитационные материалы, содержащиеся на этом сайте, изготовлены по заказу уполномоченных кандидатом лиц и оплачены средствами избирательного фонда кандидата.',
    'program_theme': 'Обращение кандидата',
    'program_title': 'Предвыборная программа кандидата в Президенты Республики Казахстан Токаева Касым-Жомарта Кемелевича',
    'program_title_small': 'Уважаемые соотечественники!',
    'program_text': '<p> Мы – единый народ Казахстана. Нас объединяют общие ценности нашей священной земли: мир, благополучие, процветание, справедливость, равные возможности для всех. </p> <p> У нашей страны великое прошлое. Мы живем в сильном, суверенном государстве. Нам есть что беречь, поэтому мы смело смотрим вперед, в будущее. Наше единство незыблемо. </p>',
    'staff': 'Региональные штабы',
    'video_letter': 'Видеообращение',
    'completed_tasks': 'Итоги работы',
    'rosh': 'Республиканский Общественный Штаб',
    'support_letter': 'Письмо',
    'back': 'Назад',
    'main': 'Главная',
    'completed_work': 'Вся проделанная работа',
    'comp_1': 'Для организации агитационной кампании кандидата в Президенты Республики Казахстан Касым-Жомарта Токаева сформирован Республиканский общественный штаб.',
    'comp_2': 'Вокруг политической фигуры господина Токаева К.К. объединились патриоты своей страны, готовые каждый день вместе строить Справедливый Казахстан.',
    'comp_3': 'Каждый из них активно участвует в агитационной кампании, разъясняет Предвыборную программу Касым-Жомарта Токаева.',
    'comp_4': 'Если вы готовы поддержать наше общественное движение, загрузите свое видео обращение на нашей странице.',
    'send_video': 'Если вы готовы поддержать наше общественное движение, загрузите свое видео обращение на нашей странице.',
    'all_init': 'Меры',
    'look': 'Посмотреть',
    'fact_1': 'Интересы государства – это интересы народа!',
    'fact_2': 'Мы построим сильное государство, в котором соблюдаются принципы демократии, верховенства закона и равенства. В нашей независимой стране растет новое, свободное духом поколение. Все кардинальные изменения мы осуществляем ради будущего подрастающего поколения.',
    'fact_3': 'У нас одна Родина, одно государство, один народ!',
    'fact_4': 'Хроника результативных дел',
    'results': 'Результаты',
    'fact_5': 'Главная цель наших реформ – построение Справедливого Казахстана',
    'staff_region': 'Выберите регион штаба',
    'leader': 'Руководитель',
    'send_2022': 'Послание 2022-2029',
    'load_more': 'Загрузить еще',
    'problem_form_1': 'Открытое государство, где общество вовлечено в процесс принятия решений и делает страну сильной',
    'problem_form_2': 'Напишите нам, мы готовы вести конструктивный диалог и своевременно реагировать на запросы граждан.',
    'fio': 'Фамилия Имя Отчество ',
    'phone_num': 'Номер телефона',
    'leave_place': 'Выбрать место жительства',
    'region_type': 'Тип населенного пункта',
    'to_main': 'Вернуться на главное',
    'nakaz': 'Предложение',
    'form2_1': 'Отправьте своё письмо кандидату в Президенты Касым-Жомарту Кемелевичу Токаеву.',
    'form2_2': '',
    'form2_3': 'Место жительства',
    'region': 'Регион',
    'district': 'Район',
    'form2_4': 'Напишите письмо поддержки или благодарности лично кандидату в Президенты Касым-Жомарту Кемелевичу Токаеву.',
    'form3_1': 'Отправьте письмо с предложениями или словами поддержки кандидату в президенты',
    'already_send': 'уже отправило',
    'write_letter': 'Написать письмо',
    'write': "Написать",
    'program_themes': 'Разделы программы',
    'program_1': 'Предвыборная платформа <p>«СПРАВЕДЛИВЫЙ КАЗАХСТАН –  ДЛЯ ВСЕХ и ДЛЯ КАЖДОГО. СЕЙЧАС и НАВСЕГДА»</p>',
    'program_2': 'Посмотреть программу',
    'program_3': 'Вы можете отправить свои идеи по созданию Справедливого Казахстана',
    'program_4': 'Написать предложение',
    'vide_letter': 'Видео письмо',
    'video1_1': 'Опубликовать своё видео письмо',
    'video2_2': 'Вставьте ссылку с youtube с вашим видео',
    'video_3': 'Некорректная ссылка',
    'video_4': '<span>1. Вставьте ссылку с youtube с вашим видео; </span>\n' +
        '                <span>2. Откройте профиль для доступа к видео;</span>\n' +
        '                <span>3. Продолжительность видео не должна превышать 1 минуты;</span>\n' +
        '                <span>\n' +
        '                  4. Запрещены высказывания, разжигающие межконфессиональную и межнациональную рознь\n' +
        '                </span>',
    'all_video': 'Видео письма Касым-Жомарту Токаеву',
    'video3_1': 'Загрузите видео обращение в поддержку кандидата в Президенты',
    'video3_2': 'Посмотреть все видео пожелания',
    'video3_3': 'Опубликовать своё видео',
    'bio_1': '',
    'bio_2': '17 мая 1953 года',
    'bio_3': 'Касым-Жомарт Токаев родился 17 мая 1953 года в городе Алма-Ата (ныне Алматы), вырос в семье интеллигентов.',
    'bio_4': 'Его отец Кемель Токаевич Токаев (1923-1986) - участник Великой Отечественной войны, известный писатель, основоположник детективно-приключенческого жанра в казахской литературе. Мать Турар Шабарбаева (1931-2000) преподавала в Алма-Атинском педагогическом институте иностранных языков.',
    'bio_5': 'ШКОЛЬНЫЕ И СТУДЕНЧЕСКИЕ ГОДЫ',
    'bio_6': '1970 – 1975 годы',
    'bio_7': 'Касым-Жомарт Токаев окончил алма-атинскую среднюю школу № 25 с углубленным изучением французского языка, где считался одним из лучших учеников.',
    'bio_8': 'В 1975 году завершил учебу в Московском государственном институте международных отношений (МГИМО МИД СССР). Студент Касым-Жомарт Токаев учился на «отлично», хотя парню из Казахстана было нелегко составлять конкуренцию детям дипломатов и государственных деятелей.',
    'bio_9': '',
    'bio_10': '',
    'bio_11': 'После окончания МГИМО Токаев начал карьеру дипломата с должности дежурного референта в Министерстве иностранных дел СССР. За успехи в работе откомандирован в советское посольство в Сингапуре.',
    'bio_12': 'В 1979 году, по завершении четырехгодичной длительной заграничной командировки, вернулся в аппарат Министерства иностранных дел СССР.',
    'bio_13': ' В 1983 году Касым-Жомарт Токаев проходил в Китае десятимесячную стажировку в Пекинском лингвистическом институте. Спустя два года, в 1985 году был направлен дипломатом в советское посольство в Пекине, где работал до 1991 года на должностях второго секретаря, первого секретаря и советника.',
    'bio_14': 'Помимо дипломатической деятельности он выступал на турнире по настольному теннису. Фотография молодого Касым-Жомарта Токаева, сделанная в 1986 году в Пекине. (фото)',
    'bio_15': '',
    'bio_16': '',
    'bio_17': 'В мае 1992 года Казахстан вступил в ЮНЕСКО. Первым объектом нашей страны, внесенным в список ЮНЕСКО, стал Мавзолей Ходжи Ахмеда Яссави.',
    'bio_18': 'На фото Касым-Жомарт Токаев с Генеральным директором ЮНЕСКО Федерико Майором, май 1992 года, Париж. (фото)',
    'bio_19': 'После обретения Казахстаном независимости Касым-Жомарт Токаев стал заместителем первого министра иностранных дел Республики Казахстан Тулеутая Сулейменова.',
    'bio_20': 'Касым-Жомарт Токаев занимал должность министра иностранных дел Республики Казахстан в общей сложности 10 лет. С 1994 по 1999 годы и с 2003 по 2007 годы.',
    'bio_21': '         В качестве министра иностранных дел вносит значительный вклад в сферу нераспространения ядерного оружия. В 1996 году в городе Нью-Йорк, на 50-й сессии Генеральной Ассамблеи ООН, Касым-Жомарт Токаев подписал Договор о всеобъемлющем запрещении ядерных испытаний. В 2005 году им подписан Договор о создании зоны, свободной от ядерного оружия, в Центральной Азии (ЦАЗСЯО) в городе Семипалатинск.',
    'bio_22': 'Из личного архива Маката Садыка. 1998 год. Поездка за границу. (фото)',
    'bio_23': 'С 1999-го по 2002-ой год Касым-Жомарт Токаев трудился заместителем Премьер-Министра, а позже работал Премьер-Министром Республики Казахстан.',
    'bio_24': '2000 год. Рабочий визит Касым-Жомарта Токаева в один из регионов страны в качестве главы Правительства. (фото)',
    'bio_25': 'Его премьерство совпало с тяжелым периодом в истории Независимого Казахстана. Под руководством Касым-Жомарта Токаева Правительство страны сумело взять курс на устойчивый экономический рост. В результате улучшился инвестиционный климат, повысился уровень занятости населения. Все это ярко свидетельствует о том, насколько приоритетным был вопрос благосостояния народа для Касым-Жомарта Токаева.',
    'bio_26': 'ГОСУДАРСТВЕННЫЙ СЕКРЕТАРЬ - МИНИСТР ИНОСТРАННЫХ ДЕЛ РЕСПУБЛИКИ КАЗАХСТАН В ПЕРИОД 2002-2003 ГОДА',
    'bio_27': 'Во время работы он встречался с Президентом США Джорджем Бушем-младшим. (фото)',
    'bio_28': 'В ПЕРИОД 2007-2011 И С 2013-2019 ГОДЫ ПРЕДСЕДАТЕЛЬ СЕНАТА ПАРЛАМЕНТА РЕСПУБЛИКИ КАЗАХСТАН',
    'bio_29': 'Касым-Жомарт Токаев возглавлял Сенат Парламента РК более 10 лет. За все эти годы у него сформировалось четкое понимание работы правовых механизмов государственности. Через Касым-Жомарта Кемелевича прошли все правовые основы и различные реформы социально-экономического развития Казахстана.',
    'bio_30': 'Отсюда и глубокие знания о принципах правового и открытого государства, о которых так часто упоминает Касым-Жомарт Токаев. Именно в тот период Парламент разработал и принял значительный пакет законодательных поправок, которые усилили демократическое устройство государства.',
    'bio_31': 'Касым-Жомарт Токаев работал заместителем Генерального секретаря ООН, главой Европейского отделения ООН в городе Женева. Являлся личным представителем Генерального секретаря ООН Пан Ги Муна на Конференции по разоружению.',
    'bio_32': 'Касым-Жомарт Токаев и Пан Ги Мун. (фото)',
    'bio_33': 'Он занимал должность генерального секретаря Конференции по разоружению. (фото)',
    'bio_34': '13 сентября 2012 год. Государственный секретарь США Джон Керри и министр иностранных дел России Сергей Лавров в сопровождении генерального директора Отделения ООН в Женеве Касым-Жомарта Токаева на встрече по принятию политического решения по гражданской войне в Сирии. (фото)',
    'bio_35': '',
    'bio_36': 'Касым–Жомарт Токаев одержал победу на внеочередных президентских выборах, набрав 70,96 % и был избран Президентом Республики Казахстан.',
    'bio_37': 'Один из первых указов Президента касается социальной защиты населения. Речь идет об облегчении долгового бремени людям, которые оказались в трудной жизненной ситуации. <br /> Он поручил Правительству и Национальному банку погасить задолженности многодетных семей и семей с детьми-инвалидами, или потерявших одного кормильца. Эта инициатива затронула 3 млн граждан или 16% населения страны.',
    'bio_38': '',
    'bio_39': 'Ознакомиться с другими инициативами Президента Касым-Жомарта Кемелевича Токаева в период с 2019-2022 годы:',
    'programs_title': 'Предвыборная программа',
    'headquarters_title': 'Республиканский общественный штаб',
    'video_title': 'Видеообращение',
    'nakaz2_title': 'Написать письмо',
    'rosh_title': 'Команда',
    'video_footer': 'Видеообращение',
    'president_letter': 'Письмо',
    'district_placeholder': 'Выбор района',
    'thx_video': 'Спасибо, что отправили видео',
    'import_link': 'Вставить ссылку',
    'on_moderation': 'Ваше видео на модерации',
    'to_moderation': 'Отправить на модерацию',
    'date_1': '1975 - 1979 годы',
    'date_2': '1983 - 1991 годы',
    'date_3': '1992 год',
    'date_4': '1992-1994 годы',
    'date_5': 'В 1996 году',
    'date_6': '2011-2013 годы',
    'date_7': '9 июня 2019 года',
    'date_8': 'Посмотреть все результаты за <br /> 2019-2022',
    'staffs': 'Региональные штабы',
    'up': 'Наверх',
    'age': 'Возраст',
    'all': 'Все',
    'fio_short': 'ФИО',
    'position': 'Должность',
    'modal_text': 'Уважаемые избиратели, Благодарим Вас за интерес к программе кандидата\n' +
        '          в Президенты Касым-Жомарта Кемелұлы Токаева. Содержание программы\n' +
        '          будет доступно с 27 октября 2022 года.\n' +
        '          <span>Спасибо за понимание!</span>',
    'staff_2': 'Здесь можно найти адрес и контакты представителя общественного штаба кандидата в Президенты Республики Казахстан Касым-Жомарта Токаева в своей области.',
    'staff_3': 'Региональные штабы',
    'staff_4': 'Доверительные лица',
    'staff_6': 'Каждый из них активно участвует в агитационной кампании, разъясняет Предвыборную программу Касым-Жомарта Токаева своим сверстникам на близком им языке.',
    'send_own_video': 'Отправить видео',
    'work_results': 'Хроника результативных дел',
    'media': 'Медиа',
    'rosh_main_1': 'Вокруг политической фигуры господина Токаева К.К. объединились патриоты своей страны, готовые каждый день вместе строить Справедливый Казахстан',
    'rosh_main_2': 'Более 450 человек со всего Казахстана, являясь лидерами в своих сферах, активно участвуют в агитационной кампании, разъясняют Предвыборную программу Касым-Жомарта Токаева.',
    'rosh_main_button': 'Посмотреть всех',
    'senten-1': 'Поддержать',
    'senten-2': 'Предложить',
    'senten-3': 'Письмо-пожелание',
    'all_prog_2022': 'Посмотреть полную версию',
    'not_found': '404 not found',
    'not_found_description': '404 description',
    'close': 'Закрыть',
    'more_descr': 'подробнее',
    'program_new_title': '<strong>Уважаемые соотечественники!</strong>',
    'program_new_text':
        '<p>Наша страна вступает в <strong>новый исторический период.</strong> </p>' +
        '<p>20 ноября 2022 года состоятся выборы Президента Республики Казахстан. <strong>Они определят стратегический курс нашего государства.</strong></p>' +
        '<p><strong>Народная коалиция</strong>, объединившая парламентские партии и крупные общественные организации, выдвинула меня кандидатом в Президенты. В связи с этим предлагаю свое видение <strong>развития Казахстана в ближайшие семь лет.</strong></p>' +
        '<p>Прежде всего, планирую инициировать кардинальные изменения в экономике, социальной сфере, региональной политике. Реформы станут <strong>эффективным ответом на чаяния народа</strong> и обеспечат устойчивость нашей страны в условиях беспрецедентных внешних вызовов.</p>' +
        '<p><strong>Наша главная цель – построить Справедливый Казахстан.</strong> Для этого нужно <strong>учесть и исправить ошибки прошлого</strong>, решить накопившиеся проблемы.</p>' +
        '<p>Государство покончит с монополиями и обеспечит <strong>справедливое распределение доходов.</strong> Каждый, кто хочет и может трудиться, найдет достойную работу. Каждому, кто нуждается в помощи, будет оказана поддержка государства и общества.</p>' +
        '<p>Мы полностью решим вопрос нехватки <strong>чистой воды</strong>, укрепим <strong>продовольственную безопасность.</strong> Государство обеспечит сельских жителей услугами <strong>профессиональной медицины.</strong> </p>' +
        '<p>Государство будет <strong>инвестировать в образование подрастающих поколений.</strong> Проблемы на всех уровнях образования найдут свое решение. В фокусе нашего внимания будет <strong>воспитание детей и их благополучие.</strong></p>' +
        '<p>Мы <strong>возродим аграрный сектор,</strong> чтобы жить и работать на селе было не менее престижно, чем в больших городах. Фермеры станут новой движущей силой развития нашей экономики.</p>' +
        '<p>Обеспечим безопасность <strong>на улицах</strong>, чтобы жители и гости страны чувствовали себя <strong>одинаково комфортно</strong> как в городах, так и в сельской местности.</p>' +
        '<p>Мы продолжим <strong>внешнеполитический курс</strong>, нацеленный на защиту национальных интересов, укрепление взаимовыгодного сотрудничества со всеми заинтересованными государствами, обеспечение международного мира и безопасности.</p>' +
        '<p>Мы перейдем к <strong>новой государственной политике</strong>, которая будет базироваться на трех взаимосвязанных принципах: \n<strong>СПРАВЕДЛИВОЕ ГОСУДАРСТВО – СПРАВЕДЛИВАЯ ЭКОНОМИКА – СПРАВЕДЛИВОЕ ОБЩЕСТВО.</strong></p>' +
        '<p>Все решения будут приниматься <strong>взвешенно, открыто и справедливо,</strong> и главное – с учетом интересов и запросов общества. Поэтому <i>первый принцип</i> предусматривает <strong>продолжение политической модернизации,</strong> внедрение профессионального государственного и корпоративного управления. Действия Правительства, акиматов, судов, прокуратуры и полиции будут направлены на решение основной задачи – обеспечение благополучия и безопасности граждан. </p>' +
        '<p><i>Второй принцип</i> означает <strong>построение экономики,</strong> основанной на <strong>балансе интересов и благополучия</strong> граждан, бизнеса, государства, а также эффективном использовании ресурсов страны. Для этого будет создан современный <strong>производительный рынок труда,</strong> обеспечивающий высокую занятость и социальную сплоченность людей, сбалансированное территориальное развитие.</p>' +
        '<p><i>Третий принцип</i> предполагает <strong>создание развитой социальной инфраструктуры</strong> и широких возможностей для реализации человеческого потенциала. Мы построим здоровое, образованное, трудолюбивое, социально защищенное и патриотичное общество.</p>' +
        '<p>Все это я намерен воплотить в жизнь уже в самое ближайшее время. <strong>Поддержка народа станет главным фактором</strong> достижения этих целей. Чем больше граждан будет вовлечено в реализацию намеченных преобразований, тем быстрее мы получим искомый результат.</p>' +
        '<p>Я искренне заинтересован в том, чтобы граждане Казахстана стали жить лучше. Важно, что это стремление разделяет <strong>вся нация.</strong></p>',
    'support_link': 'Связаться со службой поддержки',
    'support_title': 'Служба поддержки',
    'support_text': 'В случае возникновения вопросов или проблем по сайту и/или его содержанию, напишите нам.',
    'fio_placeholder_s': 'Напишите ваше имя',
    'email_placeholder_s': 'Напишите почту',
    'text_placeholder_s': 'Ваше сообщение ',
    'write_placeholder': 'Написать',
    'send_support': 'Отправить',
    'support': 'Служба поддержки',
    'pr_1': 'ЗА СПРАВЕДЛИВЫЙ КАЗАХСТАН',
    'pr_2': 'ЗА СИЛЬНЫЙ КАЗАХСТАН',
    'pr_3': 'У НАС ОДНА РОДИНА, ОДНО ГОСУДАРСТВО, ОДИН НАРОД!',
    'pr_4': 'CИЛЬНЫЙ КАЗАХСТАН — ЕДИНАЯ НАЦИЯ',
    'pr_5': 'СПРАВЕДЛИВЫЙ КАЗАХСТАН: БОГАТСТВА СТРАНЫ НАРОДУ',
    'pr_6': 'СПРАВЕДЛИВЫЙ КАЗАХСТАН — ЗАБОТА И ЗАЩИТА СЕМЬИ',
    'pr_7': 'CИЛЬНЫЙ КАЗАХСТАН:  ЗАКОН, ПОРЯДОК, БЕЗОПАСНОСТЬ',
    'pr_8': 'СПРАВЕДЛИВЫЙ КАЗАХСТАН — ДОСТОЙНАЯ СТАРОСТЬ',
    'pr_9': 'СПРАВЕДЛИВЫЙ КАЗАХСТАН — УСПЕШНОЕ ПОКОЛЕНИЕ',
    'pr_10': 'СПРАВЕДЛИВЫЙ КАЗАХСТАН — СТРАНА БЕЗ КОРРУПЦИИ',
    'pr_11': 'СПРАВЕДЛИВЫЙ КАЗАХСТАН — РАВНЫЕ ВОЗМОЖНОСТИ',
    'vote': 'Отдайте свой голос 20-го ноября',
    'news_slide_1': '«Мною движет искреннее желание надежно обеспечить долгосрочные интересы государства и народа. Для меня нет более почетной и ответственной миссии, чем служение Родине. Гарантии сменяемости власти станут мощным барьером для любых попыток монополизации власти»',
    'search': 'Поиск',
    'news_meta': 'Новости Токаев выборы в Казахстане 2022',
    'news_meta_description': 'Более 5 тысяч агитационных мероприятий в поддержку Касым-Жомарта Токаева прошли по всей стране',
    'programs_meta': 'Предвыборная программа Токаева 2022',
    'programs_meta_description': 'Токаев К.Ж. выборы 2022 Президентские выборы: предвоборная программа Токаева, новости, биолграфия, штаб.',
    'bio_meta': 'Касым Жомарт Токаев Биография президента РК',
    'bio_meta_description': 'президент Касым-Жомарт Токаев родился 17 мая 1953 года в городе Алма-Ата (ныне Алматы), вырос в семье интеллигентов.',
    'main_meta': 'Казахстан выборы 2022 К. Ж Токаев',
    'main_meta_description': 'Токаев К.Ж. выборы 2022 Президентские выборы: предвоборная программа Токаева, новости, биолграфия, штаб.',
    'headquarters_meta': 'Контакты Республиканский общественный штаб',
    'headquarters_meta_description': 'Республиканский общественный штаб Токаева 2022',
    'close_text': '19 ноября закончится агитация и будет объявлен день тишины.'


};