export const normalizeUrl = (url) => {
    if (url)
        return (url.includes('http:') || url.includes('https:')) ? url : process.env.VUE_APP_BACKEND_URL + url;
};

export default normalizeUrl();

export const changeUrlYT = (url) => {
    // let match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
    let match = url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|v(?:i)?=))([^#]*).*/);
    return match ? match[1] : false;
};

export const changeLang = (item, field,locale) => {
    return item.translations&&item.translations.length>0?item.translations.find(x => x.locale == locale)[field]:item[field]
};
